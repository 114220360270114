import { createSelector } from 'reselect';

const selectPool = (state: any) => state.data;

export const selectPoolData = createSelector(
  [selectPool],
  data => data.pool
);

export const selectMyPoolData = createSelector(
  [selectPool],
  data => data.myPool
);

export const selectCurrentBlock = createSelector(
  [selectPool],
  data => data.currentBlock
)

export const selectTxBlockRate = createSelector(
  [selectPool],
  data => data.blockRate
)

export const selectBNum = createSelector(
  [selectPool],
  data => data.bnum
)

export const selectUserDict = createSelector(
  [selectPool],
  data => data.userDict
)

export const selectIsFetchingPools = createSelector(
  [selectPool],
  data => data.isPoolsFetching
);

export const selectIsFetchingMyPools = createSelector(
  [selectPool],
  data => data.isMyPoolsFetching
);

export const SelectIsUserDataFetching = createSelector(
  [selectPool],
  data => data.isUserDataFetching
)

export const SelectIsBlockRateFetching = createSelector(
  [selectPool],
  data => data.isBlockRateFetching
)

export const selectIsCompounderFetching = createSelector(
  [selectPool],
  data => data.isCompounderFetching
)

export const selectBackersDeposit = createSelector(
  [selectPool],
  data => data.backersDeposit
);

export const selectFilteredBackersDeposit = (walletaddress: string) => createSelector(
  [selectBackersDeposit],
  backersDeposit => (backersDeposit ? backersDeposit[walletaddress] : null)
);

export const selectUserLottery = createSelector(
  [selectPool],
  data => data.userLottery
);

export const selectFilteredUserLottery = (walletaddress: string) => createSelector(
  [selectUserLottery],
  userLottery => (userLottery ? userLottery[walletaddress] : null)
);

export const selectPoolDataForPreview = createSelector(
  [selectPoolData],
  pool => pool ? Object.keys(pool).map(key => pool[key]) : []
);

export const selectMyPoolDataForPreview = createSelector(
  [selectMyPoolData],
  pool => pool ? Object.keys(pool).map(key => pool[key]) : []
);

export const selectPoolContent = (poolId: string) =>
  createSelector(
    [selectPoolData],
    pools => (pools ? pools[poolId] : null)
);

export const selectInterestdata = createSelector(
  [selectPool],
  data => data.interestData
);

export const selectFilteredData = (walletaddress: string) => createSelector(
  [selectInterestdata],
  interestData => (interestData ? interestData[walletaddress] : null)
);

export const selectTransactionData = createSelector(
  [selectPool],
  data => data.transactionData
);

export const selectMpCompounder = createSelector(
  [selectPool],
  data => data.compounder
);

export const selectMpCompounderContent = (poolId: string) =>
  createSelector(
    [selectMpCompounder],
    data => (data ? data[poolId] : null)
);
