import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import WithSpinner from '../../with-spinner/withSpinner';
import { SelectIsUserDataFetching } from '../../../redux/data/data.selectors';
import userTransaction from './userTransaction';

const mapStateToProps = createStructuredSelector({
  isLoading: SelectIsUserDataFetching
});

const YieldContainerWrapper: any = compose(
  connect(mapStateToProps),
  WithSpinner
)(userTransaction);

export default YieldContainerWrapper;