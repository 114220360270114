import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import WithSpinner from '../../../with-spinner/withSpinner';
import myPools from './myPools'
import { selectIsFetchingMyPools } from '../../../../redux/data/data.selectors';

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingMyPools
});

const YieldContainerWrapper: any = compose(
  connect(mapStateToProps),
  WithSpinner
)(myPools);

export default YieldContainerWrapper;