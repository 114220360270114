import { Disclosure } from "@headlessui/react";
import { ChevronDown } from "react-feather";

export default function FAQ() {
  return (
    <div className="w-full px-4 pt-16">
      <div className="w-full max-w-screen-xl p-2 mx-auto bg-main-01 rounded-2xl">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-medium text-left text-white bg-main-00 rounded-lg hover:bg-opacity-70 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>What is MustPool?</span>
                <ChevronDown
                  className={`${
                    open ? "transform rotate-180" : ""
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-md leading-10 text-gray-200">
                It currently offers an auto-compounder protocol for Zilliqa
                staking (<a href="https://stake.zilliqa.com/">Zillion</a>).
                Current release allows users to stake Zilliqa to different SSN
                operators on Zillion via MustPool smart contract, which takes
                care of auto-compounding their yields from Zillion. MustPool
                aims to provide a no loss prize protocol, with passive yield
                generation for different staking and lending dApps.
                <p>
                  Here is a video from{" "}
                  <a
                    href="https://youtu.be/Qr39dNi8uJk?t=2924"
                    target={"_blank"}
                    rel="noreferrer"
                    className="text-main-03 font-medium"
                  >
                    MustPool at ZilHive demo day
                  </a>
                </p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-medium text-left text-white bg-main-00 rounded-lg hover:bg-opacity-70 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>How do I use MustPool?</span>
                <ChevronDown
                  className={`${
                    open ? "transform rotate-180" : ""
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-md leading-10 text-gray-200">
                <p>
                  There exists a compounder smart contract for each of the shown
                  pools per SSN operator. It is recommended to verify by double
                  checking the contract while interacting with the pools. One
                  can do this via wallet (ZilPay) popup when signing a
                  transaction. Note that withdrawal requests from Zillion takes
                  30800 blocks, while with MustPool, it takes ~2 more reward
                  cycles 35250 blocks for a withdrawal request to mature; as the
                  contract waits for a cycle to accumulate all withdrawal
                  requests before transmitting it to Zillion.
                  <p>Below are the MustPool contract addresses: </p>
                  <ol className="list-decimal list-inside break-words">
                    <li>
                      Atomic Wallet:
                      <span>
                        <a
                          href="https://viewblock.io/zilliqa/address/zil1sv6pjk9w0wa5yfjf0w40p5akeltqrmgh4l0sv5?network=mainnet"
                          target={"_blank"}
                          rel="noreferrer"
                          className="text-main-03 font-medium"
                        >
                          {" "}
                          zil1sv6pjk9w0wa5yfjf0w40p5akeltqrmgh4l0sv5
                        </a>
                      </span>
                    </li>
                    <li>
                      Ignite DAO:
                      <span>
                        <a
                          href="https://viewblock.io/zilliqa/address/zil12frj757wkatsfszcpn4lkqgljxwpr0qzjyhgpm?network=mainnet"
                          target={"_blank"}
                          rel="noreferrer"
                          className="text-main-03 font-medium"
                        >
                          {" "}
                          zil12frj757wkatsfszcpn4lkqgljxwpr0qzjyhgpm
                        </a>
                      </span>
                    </li>
                    <li>
                      Moonlet:
                      <span>
                        <a
                          href="https://viewblock.io/zilliqa/address/zil1rjhhm7ywyldz76x3xh65fsjddscxyalh48cxhg?network=mainnet"
                          target={"_blank"}
                          rel="noreferrer"
                          className="text-main-03 font-medium"
                        >
                          {" "}
                          zil1rjhhm7ywyldz76x3xh65fsjddscxyalh48cxhg
                        </a>
                      </span>
                    </li>
                    <li>
                      ViewBlock:
                      <span>
                        <a
                          href="https://viewblock.io/zilliqa/address/zil1wxpvw3gjkfkmwqp4nwamsm9wtmj7gjctwteltf?network=mainnet"
                          target={"_blank"}
                          rel="noreferrer"
                          className="text-main-03 font-medium"
                        >
                          {" "}
                          zil1wxpvw3gjkfkmwqp4nwamsm9wtmj7gjctwteltf
                        </a>
                      </span>
                    </li>
                    <li>
                      Zillet:
                      <span>
                        <a
                          href="https://viewblock.io/zilliqa/address/zil1un0ucnmm5cpxkl6k0qjc6fda6sq426kln7lc8r?network=mainnet"
                          target={"_blank"}
                          rel="noreferrer"
                          className="text-main-03 font-medium"
                        >
                          {" "}
                          zil1un0ucnmm5cpxkl6k0qjc6fda6sq426kln7lc8r
                        </a>
                      </span>
                    </li>
                  </ol>
                </p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
