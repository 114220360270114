import { ActionTypes } from "./data.types";

export const fetchPoolsStart = () => ({
    type: ActionTypes.FETCH_POOLS_START,
});

export const fetchPoolsSuccess = (collectionsMap: object[]) => ({
    type: ActionTypes.FETCH_POOLS_SUCCESS,
    payload: collectionsMap
})

export const fetchMyPoolsStart = () => ({
    type: ActionTypes.FETCH_MYPOOLS_START,
});

export const fetchMyPoolsSuccess = (collectionsMap: object[]) => ({
    type: ActionTypes.FETCH_MYPOOLS_SUCCESS,
    payload: collectionsMap
})

export const fetchPoolsError = (errorMessage: string) => ({
    type: ActionTypes.FETCH_POOLS_FAILURE,
    payload: errorMessage
})

export const fetchMyPoolsError = (errorMessage: string) => ({
    type: ActionTypes.FETCH_MYPOOLS_FAILURE,
    payload: errorMessage
})

export const fetchCurrentBlockSuccess = (currentBlock: string) => ({
    type: ActionTypes.FETCH_CURRENT_BLOCK_SUCCESS,
    payload: currentBlock
})

export const fetchBlockRateSuccess = (blockRate: string) => ({
    type: ActionTypes.FETCH_BLOCK_RATE_SUCCESS,
    payload: blockRate
})

export const fetchContractStateStart = (compounder: object) => ({
    type: ActionTypes.FETCH_CONTRACT_STATE_START,
    compounder
});

export const fetchbnumSuccess = (bNum: object[]) => ({
    type: ActionTypes.FETCH_B_NUM_SUCCESS,
    payload: bNum
})

export const fetchUserWithDictSuccess = (userDict: object[]) => ({
    type: ActionTypes.FETCH_USER_WITHDRAW_DICT_SUCCESS,
    payload: userDict
})

export const fetchInterestSuccess = (interestData: object) => ({
    type: ActionTypes.FETCH_INTEREST_DATA_SUCCESS,
    payload: interestData
})

export const fetchBackersDeposit = (deposit: number) => ({
    type: ActionTypes.FETCH_BACKERS_DEPOSIT_SUCCESS,
    payload: deposit
})

export const fetchUserLotterySuccess = (userLottery: object[]) => ({
    type: ActionTypes.FETCH_USER_LOTTERY_SUCCESS,
    payload: userLottery
})

export const fetchContractStateError = (errorMessage: string) => ({
    type: ActionTypes.FETCH_CONTRACT_STATE_FAILURE,
    payload: errorMessage
})

export const fetchMpCompounderStart = (loading: boolean) => ({
    type: ActionTypes.FETCH_MP_COMPOUNDER_START,
    payload: loading
})

export const fetchMpCompounderSuccess = (data: object) => ({
    type: ActionTypes.FETCH_MP_COMPOUNDER_SUCCESS,
    payload: data
})

export const fetchMpCompounderFailure = (errorMessage: string) => ({
    type: ActionTypes.FETCH_MP_COMPOUNDER_FAILURE,
    payload: errorMessage
})