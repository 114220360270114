import {transactionTypes} from "./transaction.types";

export const depositFunctionStart = (data: object) => ({
    type: transactionTypes.START_DEPOSIT_TRANSACTION,
    data
});

export const depositFunctionSuccess = (txObject: object) => ({
    type: transactionTypes.DEPOSIT_TRANSACTION_SUCCESS,
    payload: txObject
})

export const depositFunctionFailure = (error: string) => ({
    type: transactionTypes.DEPOSIT_TRANSACTION_FAILURE,
    payload: error
})

export const withdrawFunctionStart = (data: object) => ({
    type: transactionTypes.START_WITHDRAW_TRANSACTION,
    data
});

export const withdrawFunctionSuccess = (TxData: object) => ({
    type: transactionTypes.WITHDRAW_TRANSACTION_SUCCESS,
    payload: TxData
})

export const withdrawFunctionFailure = (error: string) => ({
    type: transactionTypes.WITHDRAW_TRANSACTION_FAILURE,
    payload: error
})

export const changeInterestFunctionStart = (data: object) => ({
    type: transactionTypes.START_CHANGE_INTEREST_TRANSACTION,
    data
});

export const changeInterestFunctionSuccess = (TxData: string) => ({
    type: transactionTypes.CHANGE_INTEREST_TRANSACTION_SUCCESS,
    payload: TxData
})

export const changeInterestFunctionFailure = (error: string) => ({
    type: transactionTypes.CHANGE_INTEREST_TRANSACTION_FAILURE,
    payload: error
})

export const claimStakeFunctionStart = (data: object) => ({
    type: transactionTypes.START_CLAIM_STAKE_TRANSACTION,
    data
});

export const claimStakeFunctionSuccess = (TxData: object) => ({
    type: transactionTypes.CLAIM_STAKE_TRANSACTION_SUCCESS,
    payload: TxData
})

export const claimStakeFunctionFailure = (error: string) => ({
    type: transactionTypes.CLAIM_STAKE_TRANSACTION_FAILURE,
    payload: error
})

export const claimLotteryFunctionStart = (data: object) => ({
    type: transactionTypes.START_CLAIM_LOTTERY_TRANSACTION,
    data
});

export const claimLotteryFunctionSuccess = (TxData: string) => ({
    type: transactionTypes.CLAIM_LOTTERY_TRANSACTION_SUCCESS,
    payload: TxData
})

export const claimLotteryFunctionFailure = (error: string) => ({
    type: transactionTypes.CLAIM_LOTTERY_TRANSACTION_FAILURE,
    payload: error
})