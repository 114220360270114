import { ReactElement } from "react";
import "./modal.css"

type props = {
    openModal: boolean,
    children: ReactElement
}

const Modal: React.FC<props> = ({openModal, children}) => {

    return (
    <div>
        <div  className={`${openModal ? "fadeIn-modal flex " : "fadeOut-modal hidden"} main-modal fixed w-full h-100 inset-0 z-50  overflow-hidden justify-center items-center animated-modal fadeIn-modal faster`}
            style={{background: "rgba(0,0,0,0.7"}}>
            <div style={{backgroundImage: "linear-gradient(to top, rgb(11, 22, 30), rgb(12, 24, 32), rgb(13, 25, 33), rgb(14, 26, 35), rgb(14, 28, 37))", boxShadow: "-1px 1px 10px 2px rgba(19,34,44,0.3)"}} className="p-5 shadow-lg modal-container w-11/12 md:max-w-md mx-auto rounded z-50 overflow-y-auto">
                 {children}
            </div>
        </div>
    </div>
     );
}
 
export default Modal;