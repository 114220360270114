import { WalletProvider } from "../../sdk/autocompounder/index";
import { Network } from "../../sdk/utils/constants";

export type ConnectOptionType = "zilpay" | "boltX" | "privateKey" | "zeeves";

export enum WalletConnectType {
  Moonlet, PrivateKey, ZilPay, Zeeves, BoltX
};

export type WalletAccountInfo = {
  byte20: string;
  bech32: string;
  privateKey?: string;
};

export type ConnectedWallet = {
  provider?: WalletProvider;
  type: WalletConnectType;
  network: Network;
  addressInfo: WalletAccountInfo;
};

export type ConnectWalletResult = {
  wallet?: ConnectedWallet;
  error?: any;
};