import { takeLatest, call, put, all, select } from 'redux-saga/effects'

import {firestore, convertCollectionsSnapshotToMap} from '../../firebase/firebase'

import { fetchPoolsSuccess ,fetchPoolsError, fetchBlockRateSuccess, fetchCurrentBlockSuccess, fetchContractStateError, fetchBackersDeposit, fetchMyPoolsSuccess, fetchMyPoolsError, fetchUserWithDictSuccess } from './data.actionDispatcher'

import { ActionTypes } from './data.types'

import { fetchMpCompounder } from '../wallet/wallet.saga'
import { getWallet } from '../selector'
import { getData } from '../selector'
import { fetchUserDeposit } from '../../utils/fetchUserDeposit'

export interface ResponseGenerator{
    snapshot: any,
    contractaProxy:any,
    data: {currentBlock?: string; currentTxBlockRate?: string} | any
    user_withdraw_dict: object[]
    bnum_required: object[]
    backers_p_interest: object[]
    backersDeposit: object[]
    backers_realized_deposit: any
    user_lotteryPrize: object[]
    myPools?: never[]
    contractState: any
}

export function* fetchPoolsAsync() {

    const { wallet } = yield select(getWallet)

    let network
    if (wallet == null) {
        network = "mainnet"
    } else {
        network = wallet.network
    }
    
    try {
        const collectionRef = firestore.collection(`zilliqa/${network}/pools/`);;
        const snapshot: ResponseGenerator = yield collectionRef.get();

        const docRef = firestore.collection("zilliqa").doc(`${network}`);
        const docSnapshot: ResponseGenerator = yield docRef.get();
        const {currentBlock, currentTxBlockRate} = docSnapshot.data()
        const collectionMap: object[] = yield call(
            convertCollectionsSnapshotToMap,
            snapshot
        );
        yield all([
            put(fetchPoolsSuccess(collectionMap)),
            put(fetchBlockRateSuccess(currentTxBlockRate)),
            put(fetchCurrentBlockSuccess(currentBlock)),
            call(fetchMpCompounder)
        ])
    } catch (err: any) {
        yield put(fetchPoolsError(err.message));
    };
};

export function* fetchContractDataAsync({compounder}: any) {
    const { currentBlock } = yield select(getData)

    try {
        console.log("contract State Start", compounder)
        const { wallet } = yield select(getWallet)
        const base16 = wallet.addressInfo.byte20
        
        const initialAppState: ResponseGenerator = yield compounder.getAppState()

        let backersRealizedDeposit = parseInt(initialAppState["contractState"]["backers_realized_deposit"][base16.toLowerCase()]);

        if (isNaN(backersRealizedDeposit)) {
            backersRealizedDeposit = 0
        }

        const usersTotalDeposit = fetchUserDeposit(initialAppState, base16)
        
        const user_withdraw_request = initialAppState["contractState"]["user_withdraw_dict"][base16.toLowerCase()];
    
        const bnum_required = initialAppState["contractState"]["bnum_required"];

        let withdrawDataDict = []

        if (currentBlock !== null) {
            for (const request_block_num in user_withdraw_request) {
              let user_withdraw_amount = user_withdraw_request[request_block_num];
              let blocks_done = Number(currentBlock) - Number(request_block_num);
              let progress_per = (blocks_done / Number(bnum_required)) * 100;
              progress_per = Math.round(progress_per * 100) / 100;
              let blocksPending = bnum_required - blocks_done;
              const withdrawData = {request_block_num, blocksPending, progress_per, user_withdraw_amount}
              withdrawDataDict.push(withdrawData)
            }
        }

        //const userWithdrawDict = contractState["contractState"]["backers_realized_deposit"]
        // const BNum = contractState["contractState"]["bnum_required"] ;
        //const interestData: ResponseGenerator = yield contract.getSubState("backers_p_interest");
        //const backersDeposit: ResponseGenerator = yield contract.getSubState("backers_current_deposit");
        //console.log({contractState})
        //const userLottery: ResponseGenerator = yield contract.getSubState("user_lotteryPrize");
  
        yield all([
            put(fetchBackersDeposit(usersTotalDeposit)),
            put(fetchUserWithDictSuccess(withdrawDataDict))
            //put(fetchUserWithDictSuccess(userWithdrawDict)),
            //put(fetchbnumSuccess(BNum)),
            //put(fetchInterestSuccess(interestData.backers_p_interest)),
            //put(fetchBackersDeposit(backersDeposit.backers_current_deposit)),
            //put(fetchUserLotterySuccess(userLottery.user_lotteryPrize))
        ])
      } catch (err: any) {
          console.log(err)
          yield put(fetchContractStateError(err.message))
      }
}

export function* fetchMyPoolsAsync() {

    const { pool, compounder } = yield select(getData)
    const poolData = Object.keys(pool).map(key => pool[key])
    const { wallet } = yield select(getWallet)
    const walletBase16 = wallet.addressInfo.byte20

    try {
        const backersDeposit = async (allPools: any, walletBase16: any, compounder: any) => {
            const base16 = walletBase16
            const mpCompounder = compounder[allPools.id]
            const intialAppState: ResponseGenerator = await mpCompounder.getAppState()

            let usersTotalDeposit = fetchUserDeposit(intialAppState, base16)

            return usersTotalDeposit
        }

        const myPoolsInfo = async(poolData: any[]) => {
            const myPools: any = [];
            let i = 0;
            while (i < poolData.length) {
              const poolDeposit = await backersDeposit(poolData[i], walletBase16, compounder);
              if (poolDeposit !== 0) {
                myPools.push(poolData[i]);
              }
              i++;
            }
            return myPools
        }
    
        yield put(fetchMyPoolsSuccess(yield myPoolsInfo(poolData)))

    } catch (err: any) {
        console.log(err)
        yield put(fetchMyPoolsError(err.message))
    }
}

export function* fetchMyPools() {
    yield call(fetchMyPoolsAsync)
}

export function* fetchPoolsStart() {
    yield takeLatest(ActionTypes.FETCH_POOLS_START,
        fetchPoolsAsync,
        );
};

export function* fetchContractStateStart() {
    yield takeLatest(ActionTypes.FETCH_CONTRACT_STATE_START,
        fetchContractDataAsync)
}

export function* poolDataSaga() {
    yield(all([
        call(fetchPoolsStart),
        call(fetchContractStateStart),
        //call(fetchMyPoolsStart),
    ])
    );
};