import firebase from "firebase/app";
import "firebase/firestore";

//TODO:: move all credentials to .env file
// const firebaseConfig = {
//   apiKey: "AIzaSyAmeDa_RpbJkBtKtnpvFl3VT6T6RXSFE2A",
//   authDomain: "loess-c39b2.firebaseapp.com",
//   projectId: "loess-c39b2",
//   storageBucket: "loess-c39b2.appspot.com",
//   messagingSenderId: "552386605201",
//   appId: "1:552386605201:web:b57cdeeab07bd9413a8f30",
//   measurementId: "G-BLKKWNRL1D",
// };

const firebaseConfig = {
  apiKey: "AIzaSyCbC_IZ3JCTQicuiuhtdKmj8gFXFmYV6z8",
  authDomain: "mustpool-d964f.firebaseapp.com",
  projectId: "mustpool-d964f",
  storageBucket: "mustpool-d964f.appspot.com",
  messagingSenderId: "504254953613",
  appId: "1:504254953613:web:68e098b493b14738428ac4",
  measurementId: "G-2CTT30R0Z6",
};

export const convertCollectionsSnapshotToMap = (collectionPath) => {
  const collection = collectionPath.docs.map((doc) => {
    const {
      conditions,
      asset,
      apyInfo,
      prizeAmount,
      contractName,
      network,
      mustpoolContract,
      yieldContract,
      frequencyInBlocks,
      blocksToNextDraw,
      losslessInitCreationBlock,
      secsLeft,
      assetIcon,
      govTokenApy,
      baseAssetApy,
      poolIndex,
      poolLiquidityDollars,
      liquidity
    } = doc.data();
    return {
      id: doc.id,
      routeName: doc.id,
      conditions,
      asset,
      apyInfo,
      prizeAmount,
      contractName,
      network,
      mustpoolContract,
      yieldContract,
      frequencyInBlocks,
      blocksToNextDraw,
      losslessInitCreationBlock,
      secsLeft,
      assetIcon,
      govTokenApy,
      baseAssetApy,
      poolIndex,
      poolLiquidityDollars,
      liquidity
    };
  });

  return collection.reduce((accumulator, collectionPath) => {
    accumulator[collectionPath.id.toLowerCase()] = collectionPath;
    return accumulator;
  }, {});
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);
window.firebase = firebase;
export default firebase;
