import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { depositOpen } from "../../redux/modal/modalActionDispatcher";
import { depositFunctionStart } from "../../redux/transaction/transaction.actionDispatcher";
import CustomButton from "../customButton/button";
import CustomInput from "../customInput/customInput";
import { fetchUserBalance } from "../wallet/wallet";
import Max from "./max";
import Modal from "./Modal";
import { RefreshCcw } from "react-feather"
import "../with-spinner/withSpinner.css"

type props = {
    modalData: any
    openModal: any,
    closeModal: any
}

const DepositModal: React.FC<props> = ({openModal, closeModal, modalData}) => {

    console.log({modalData})

    const dispatch = useDispatch()

    const {stakingContactLocal, mpCompounder, asset, contractName} = modalData

    const [deposit_amount, setDeposit_amount] = useState(10);
    //const [restake_percent, setRestake_percent] = useState(10);
    const [userBalance, setUserBalance] = useState(0)
    const [fetchingBalance, setFetchingBalance] = useState(false)

    const value = useSelector((state: any) => state.modal.selectedValue)
    const { provider } = useSelector((state: any) => state.wallet.wallet)

    const updatedeposit_amount = (e: any) => {
        let { value } = e.target;
        const deposit_amount = parseFloat(value);
        setDeposit_amount(deposit_amount)
    };

    useEffect(() => {
        const deposit_amount = userBalance*value/100
        setDeposit_amount(deposit_amount)
    }, [value, userBalance])

    //const updaterestake_percent = (e: any) => {
        //let { value } = e.target;
        //const restake_percent = parseInt(value);
        //setRestake_percent(restake_percent);
    //};

    const UserBalance = useCallback(async () => {
        setFetchingBalance(true)
        setUserBalance(await fetchUserBalance(provider))
        setFetchingBalance(false)
    }, [provider])

    useEffect(() => {     
        UserBalance()
    }, [UserBalance, provider])

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        closeModal(false)
        dispatch(depositOpen(false, null))
        dispatch(depositFunctionStart({mpCompounder, deposit_amount, stakingContactLocal}))
    };

    return ( 
        <Modal openModal={openModal}>
            <div className="text-white">
                <div>
                    <h1 className="text-lg mb-5 text-center leading-6 font-medium">
                        Deposit {asset} to {contractName} via MustPool
                    </h1>
                    <div className="flex mt-2 justify-end">
                        {fetchingBalance ? 
                            <div className="lds-ring"><div className="white"></div></div> 
                            :
                            <div className="flex gap-2 items-center">
                                <p className="text-xs font-medium text-white">Balance {userBalance}<span className="font-semibold text-main-02"> ZIL</span></p>
                                <div className="cursor-pointer" onClick={() => UserBalance()} ><RefreshCcw size={14} color="rgba(84, 152, 127)" /></div>
                            </div>
                        }
                    </div> 
                    <form onSubmit={handleSubmit} className="mt-2">
                        <CustomInput
                            type="number"
                            placeholder="10"
                            value={deposit_amount}
                            onChange={updatedeposit_amount}
                            step="any"
                        />
                        {/*<p className="mt-5 mb-2" >Lottery Pool contribution from Rewards (%) </p>
                        <CustomInput
                            type="number"
                            placeholder="10"
                            value={restake_percent}
                            onChange={updaterestake_percent}
                        />*/}
                        <Max />
                        {deposit_amount < 10 ? <p className="text-xs mt-2 font-semibold text-red-400 italic">* Enter value above 10</p> : null}
                        {deposit_amount > userBalance ? <p className="text-xs mt-2 font-semibold text-red-400 italic">* Please enter value less than your available Balance</p> : null }
                    </form>
                </div>
                <div className="mt-5 flex justify-start gap-5 ">
                    <button
                        type="button"
                        className="mt-3 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => closeModal(false)}
                    >
                        Cancel
                    </button>
                    <CustomButton
                        disabled={((deposit_amount >= 10) && (deposit_amount <= userBalance)) ? false : true}
                        onClick={(e) => handleSubmit(e)}
                        >
                        Pool In
                    </CustomButton>
                </div>
            </div>
        </Modal>
     );
}
 
export default DepositModal;