import { Route } from "react-router";
import allPools from "../components/Pools/pools-overview/allPools/allpools.components";
import myPools from "../components/Pools/pools-overview/myPools/mypools.components";
import PoolPage from "./poolPage.container";

type props = {
    match: {path: string},
}

const YieldsPage: React.FC<props> = ({match}) => {

    return (
        <div className="" >
            <Route 
                exact
                path={`${match.path}`}
                component={myPools}
            />
            <Route 
                path={`${match.path}/my-pools/:poolId`}
                component={PoolPage}
            />
            <Route 
                exact
                path={`${match.path}`}
                component={allPools}
            />
            <Route 
                path={`${match.path}/all-pools/:poolId`}
                component={PoolPage}
            />           
        </div>
     );
}

export default YieldsPage
