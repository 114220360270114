import toast from 'react-hot-toast';
import { takeLatest, call, put, all } from 'redux-saga/effects'
import { depositFunctionFailure, depositFunctionSuccess } from '../transaction.actionDispatcher';
import {transactionTypes} from '../transaction.types';
import { Transaction } from "@zilliqa-js/account";


export interface ResponseGenerator{
    depositTransition: Promise<Transaction>,
    hash: string
}

export function* depositFunctionAsync({data}: any) {
    const {mpCompounder, deposit_amount, stakingContactLocal} = data
    const zil_amount_in_zil = deposit_amount * 1000000000000

    console.log({mpCompounder, stakingContactLocal})
    try {
        const depositTransition: ResponseGenerator = yield mpCompounder.PoolIn(zil_amount_in_zil, stakingContactLocal);
        console.log("Tx broadcasted, Waiting for tx finish!");
        if (depositTransition) {
            console.log(`\ntx hash: ${depositTransition.hash}\n`);
            const hash = depositTransition.hash
            yield put(depositFunctionSuccess({hash,  mpCompounder}))
        }
    } catch (error: unknown) {
        console.log({error})
        if (typeof error == "string") { 
          yield put(depositFunctionFailure(error))
          toast.error(error)
        }
    }
}

export function* depositFunctionStart() {
    yield takeLatest(transactionTypes.START_DEPOSIT_TRANSACTION,
        depositFunctionAsync)
}

export function* depositTransactionSagas() {
    yield(all([
        call(depositFunctionStart),
    ])
    );
};