import toast from 'react-hot-toast';
import { takeLatest, call, put, all } from 'redux-saga/effects'
import { claimStakeFunctionFailure, claimStakeFunctionSuccess } from '../transaction.actionDispatcher';
import {transactionTypes} from '../transaction.types';
import { Long } from "@zilliqa-js/util";

export interface ResponseGenerator{
    hash: string,
    ID: string
}

export function* claimStakeFunctionAsync(data: any) {
    const {mpCompounder} = data.data
    try {
        let gasLimit = Long.fromNumber(25000);
        mpCompounder.update_txParams(gasLimit)
        const claimStake: ResponseGenerator = yield mpCompounder.ClaimMaturedStake();
        if (claimStake) {
            console.log(`\ntx hash: ${claimStake.hash}\n`);
            const hash = claimStake.hash
            yield put(claimStakeFunctionSuccess({hash,  mpCompounder}))
        }
    } catch (error: unknown) {
        console.log({error})
        if (typeof error == "string") { 
          yield put(claimStakeFunctionFailure(error))
          toast.error(error)
        }
    }
}

export function* claimStakeFunctionStart() {
    yield takeLatest(transactionTypes.START_CLAIM_STAKE_TRANSACTION,
        claimStakeFunctionAsync)
}

export function* claimStakeTransactionSagas() {
    yield(all([
        call(claimStakeFunctionStart),
    ])
    );
};