import { RootStateOrAny, useSelector } from "react-redux"

const NetworkBadge = () => {

    const wallet = useSelector((state: RootStateOrAny) => state.wallet.wallet)

    let network

    if (wallet !== null) {
        network = wallet.network
    }

    return (
        <div>
            {network === "testnet" ? <div className="absolute bg-main-03 text-black tracking-wider font-medium shadow-md rounded-br-lg rounded-b-sm text-center px-2 py-1 text-xs" >TestNet</div> : null}       
        </div>
    )
}

export default NetworkBadge