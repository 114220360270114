import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import WithSpinner from '../../../with-spinner/withSpinner';
import allPools from './allPools'
import { selectIsFetchingPools } from '../../../../redux/data/data.selectors';

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingPools
});

const allPoolsContainer: any = compose(
  connect(mapStateToProps),
  WithSpinner
)(allPools);

export default allPoolsContainer;