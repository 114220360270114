import { BN, Long } from "@zilliqa-js/util";
import { Arguments } from "../utils/types";
import { Mutex } from "async-mutex";
import { BigNumber } from "bignumber.js";
import {
  Wallet,
  Transaction,
  TxReceipt as _TxReceipt,
} from "@zilliqa-js/account";
import { Contract, Value, CallParams } from "@zilliqa-js/contract";
import { Zilliqa } from "@zilliqa-js/zilliqa";
import { fromBech32Address } from "@zilliqa-js/crypto";
import { APIS, CHAIN_VERSIONS, WSS, Network } from "../utils/constants";
import {
  StatusType,
  MessageType,
  NewEventSubscription,
} from "@zilliqa-js/subscriptions";
import { sendBatchRequest, BatchRequest } from "../utils/batch";

export type TxParams = {
  version: number;
  gasPrice: BN;
  gasLimit: Long;
};

// The tx status of an observed tx.
// Confirmed = txn was found, confirmed and processed without reverting
// Rejected = txn was found, confirmed, but had an error and reverted during smart contract execution
// Expired = current block height has exceeded the txn's deadline block
export type TxStatus = "confirmed" | "rejected" | "expired";

export type TxReceipt = _TxReceipt;

export type ObservedTx = {
  hash: string;
  deadline: number;
};

export type OnUpdate = (
  tx: ObservedTx,
  status: TxStatus,
  receipt?: TxReceipt
) => void;

const contractStates = [
  // represent init value, and can be modified by the owner/admin
  "contract_owner",
  "contract_automator",
  "staking_contract_proxy",
  "staking_contract",
  "ssn_add",
  "ownership_transfer_contract",
  // user balance accounting
  "_balance",
  "backers_buffered_deposit",
  "backers_realized_deposit",
  "total_sum",
  "current_yield",
  "user_withdraw_dict",
  "matured_amount",
  "withdraw_amount",
  // can be modified by the owner / admin
  "bnum_required",
  "max_deposit",
  "rsc_min_stake_amount",
  "zillion_last_reward_cycle",
  "lock_value",
  "whitelisted_users",
];

export type TokenDetails = {
  name: string;
  symbol: string;
  decimals: number;
};

export type ContractState = {
  // represent init value, and can be modified by the owner/admin
  contract_owner: any;
  contract_automator: any;
  staking_contract_proxy: any;
  staking_contract: any;
  ssn_add: any;
  ownership_transfer_contract: any;
  // user balance accounting
  _balance: any;
  backers_buffered_deposit: any;
  backers_realized_deposit: any;
  total_sum: any;
  current_yield: any;
  user_withdraw_dict: any;
  matured_amount: any;
  withdraw_amount: any;
  // can be modified by the owner / admin
  bnum_required: any;
  max_deposit: any;
  rsc_min_stake_amount: any;
  zillion_last_reward_cycle: any;
  lock_value: any;
  whitelisted_users: any;
};

export type AppState = {
  contractState: ContractState;
  currentUser: string | null;
  currentNonce: number | null;
  currentBalance: BigNumber | null;
};

type RPCBalanceResponse = { balance: string; nonce: string };

export type WalletProvider = Omit<
  Zilliqa & {
    wallet: Wallet & {
      net: string;
      defaultAccount: { base16: string; bech32: string };
      isEnable: any;
    };
  }, // ugly hack for zilpay non-standard API
  "subscriptionBuilder"
>;

export class MP_AutoCompounder {
  /* Zilliqa SDK */
  readonly zilliqa: Zilliqa;
  network_wss: any;
  rpcEndpoint: string;
  objId: string;

  /* Contract attributes */
  readonly contract: Contract;
  readonly contract_address_bech32: string;
  readonly contract_base16: string;

  // private observer: OnUpdate | null = null;
  private observerMutex: Mutex;
  private observedTxs: ObservedTx[] = [];
  private subscription: NewEventSubscription | null = null;
  private observer: OnUpdate | null = null;
  private readonly walletProvider?: WalletProvider; // zilpay
  private appState?: AppState; // cached blockchain state for dApp and user

  /* Deadline tracking */
  private deadlineBuffer: number = 3;
  private currentBlock: number = -1;

  ///* Deadline tracking */
  // private deadlineBuffer: number = 3;
  // private currentBlock: number = -1;

  /* Transaction attributes */
  readonly _txParams: TxParams = {
    version: -1,
    gasPrice: new BN(0),
    gasLimit: Long.fromNumber(25000),
  };

  // wallet_pub_key: string;

  constructor(
    network: Network,
    contract_address: string,
    objId: string,
    walletProviderOrKey?: WalletProvider | string,
    rpcEndpoint?: string
  ) {
    this.objId = objId;
    this.rpcEndpoint = rpcEndpoint ?? APIS[network];
    this.network_wss = WSS[network];
    if (typeof walletProviderOrKey === "string") {
      this.zilliqa = new Zilliqa(this.rpcEndpoint);
      this.zilliqa.wallet.addByPrivateKey(walletProviderOrKey);
    } else if (walletProviderOrKey) {
      this.zilliqa = new Zilliqa(
        this.rpcEndpoint,
        walletProviderOrKey.provider
      );
      this.walletProvider = walletProviderOrKey;
    } else {
      this.zilliqa = new Zilliqa(this.rpcEndpoint);
    }

    this.contract_address_bech32 = contract_address;
    this.contract = (this.walletProvider || this.zilliqa).contracts.at(
      this.contract_address_bech32
    );
    this.contract_base16 = fromBech32Address(
      this.contract_address_bech32
    ).toLowerCase();
    this._txParams.version = CHAIN_VERSIONS[network];
    this.observerMutex = new Mutex();
  }

  public async initialize(
    subscription?: OnUpdate,
    observeTxs: ObservedTx[] = []
  ) {
    this.observedTxs = observeTxs;
    if (subscription) this.observer = subscription;

    const minGasPrice = await this.zilliqa.blockchain.getMinimumGasPrice();
    if (!minGasPrice.result) throw new Error("Failed to get min gas price.");
    this._txParams.gasPrice = new BN(minGasPrice.result);
    this.subscribeToAppChanges();
    await this.updateAppState();
    await this.updateBlockHeight();
    await this.updateBalanceAndNonce();
  }

  public update_txParams(gasLimit?: Long, gasPrice?: BN) {
    if (gasPrice) this._txParams.gasPrice = gasPrice;
    if (gasLimit) this._txParams.gasLimit = gasLimit;
  }

  private nonce(): number {
    return this.appState!.currentNonce! + this.observedTxs.length + 1;
  }

  public txParams(): TxParams & { nonce: number } {
    return {
      nonce: this.nonce(),
      ...this._txParams,
    };
  }

  TRANS = {
    MUSTPOOL_USER_TRANS: {
      POOL_IN: "UserPoolIn",
      POOL_OUT: "UserPoolOut",
      CLAIM_MATURED_STAKE: "UserClaimMaturedStake",
    },
    MUSTPOOL_AUTOMATOR_TRANS: {
      FETCH_DISTRIBUTE_ADD_TRANSFER_WITHDRAW_RESTAKE:
        "AutomatorFetchDistributeAddTransferWithdrawRestake",
    },
    MUSTPOOL_ADMIN_TRANS: {
      LOCK_CONTRACT: "OwnerLockContract",
      UNLOCK_CONTRACT: "OwnerUnlockContract",
      UPDATE_BNUM_REQUIRED: "OwnerUpdateBNumRequired",
      UPDATE_ZILLION_CYCLE_NUMBER: "OwnerUpdateZillionCycleNumber",
      UPDATE_MAX_DEPOSIT: "OwnerUpdateMaxDeposit",
      WHITELIST_USER: "OwnerWhitelistUser",
      UPDATE_RSC_MIN_STAKE_AMOUNT: "OwnerUpdateRSCMinStakeAmount",
      ADD_FUNDS_FROM_PREV_CONTRACT: "AddFundsFromPrevContract",
      OWNER_MIGRATE_FIELDS_FROM_OLD_VERSION: "OwnerMigrateFieldsFromOldVerion",
      OWNER_CONFIRM_TRANSFER_STAKE_OWNERSHIP_FROM_OLD_ADDRESS:
        "OwnerConfirmTransferStakeOwnershipFromOldAddress",
      OWNER_REQUEST_TRANSFER_STAKE_OWNERSHIP_TO_NEW_ADDRESS:
        "OwnerRequestTransferStakeOwnershipToNewAddress",
      OWNER_DRAIN_CONTRACT_BALANCE_TO_NEW_ADDRESS:
        "OwnerDrainContractBalanceToNewAddress",
    },
  };

  public async updateAppState(): Promise<void> {
    // const currentUser = this.wallet_pub_key;
    // Get user address
    const currentUser = this.walletProvider
      ? // ugly hack for zilpay provider
        this.walletProvider.wallet.defaultAccount.base16.toLowerCase()
      : this.zilliqa.wallet.defaultAccount?.address?.toLowerCase() || null;
    const requests: BatchRequest[] = [];
    const address = this.contract_base16.replace("0x", "");
    for (let i = 1; i < contractStates.length + 1; i++) {
      requests.push({
        id: String(i),
        method: "GetSmartContractSubState",
        params: [address, contractStates[i - 1], []],
        jsonrpc: "2.0",
      });
    }
    const result = await sendBatchRequest(this.rpcEndpoint, requests);
    const contractState = Object.values(result).reduce(
      (a, i) => ({
        ...a,
        ...i,
      }),
      {}
    ) as ContractState;
    this.appState = {
      contractState,
      currentUser,
      currentNonce: this.appState?.currentNonce || null,
      currentBalance: this.appState?.currentBalance || null,
    };
  }

  public async callContract(
    contract: Contract,
    transition: string,
    args: Value[],
    params: CallParams,
    toDs?: boolean
  ): Promise<Transaction> {
    if (this.walletProvider) {
      // ugly hack for zilpay provider
      const txn = await (contract as any).call(transition, args, params, toDs);
      txn.id = txn.ID;
      txn.isRejected = function (this: { errors: any[]; exceptions: any[] }) {
        return this.errors.length > 0 || this.exceptions.length > 0;
      };
      return txn;
    } else {
      return await contract.callWithoutConfirm(transition, args, params, toDs);
    }
  }

  /**
   * Observes the given transaction until the deadline block.
   *
   * Calls the `OnUpdate` callback given during `initialize` with the updated ObservedTx
   * when a change has been observed.
   *
   * @param observedTx is the txn hash of the txn to observe with the deadline block number.
   */
  public async observeTx(observedTx: ObservedTx) {
    const release = await this.observerMutex.acquire();
    try {
      this.observedTxs.push(observedTx);
    } finally {
      release();
    }
  }

  public deadlineBlock(): number {
    return this.currentBlock + this.deadlineBuffer!;
  }

  private async updateBlockHeight(): Promise<void> {
    const response = await this.zilliqa.blockchain.getNumTxBlocks();
    const bNum = parseInt(response.result!, 10);
    this.currentBlock = bNum;
  }

  private async updateBalanceAndNonce() {
    if (this.appState?.currentUser) {
      try {
        const res: RPCBalanceResponse = (
          await this.zilliqa.blockchain.getBalance(this.appState.currentUser)
        ).result;
        if (!res) {
          this.appState.currentBalance = new BigNumber(0);
          this.appState.currentNonce = 0;
          return;
        }
        this.appState.currentBalance = new BigNumber(res.balance);
        this.appState.currentNonce = parseInt(res.nonce, 10);
      } catch (err) {
        // ugly hack for zilpay non-standard API
        if ((err as any).message === "Account is not created") {
          this.appState.currentBalance = new BigNumber(0);
          this.appState.currentNonce = 0;
        }
      }
    }
  }

  /**
   * Gets the currently observed transactions.
   */
  public async getObservedTxs(): Promise<ObservedTx[]> {
    const release = await this.observerMutex.acquire();
    try {
      return [...this.observedTxs];
    } finally {
      release();
    }
  }

  private async updateObservedTxs() {
    const release = await this.observerMutex.acquire();
    try {
      const removeTxs: string[] = [];
      const promises = this.observedTxs.map(async (observedTx: ObservedTx) => {
        try {
          const result = await this.zilliqa.blockchain.getTransactionStatus(
            observedTx.hash
          );

          if (result && result.modificationState === 2) {
            // either confirmed or rejected
            const confirmedTxn = await this.zilliqa.blockchain.getTransaction(
              observedTx.hash
            );
            const receipt = confirmedTxn.getReceipt();
            const txStatus = confirmedTxn.isRejected()
              ? "rejected"
              : receipt?.success
              ? "confirmed"
              : "rejected";
            if (this.observer) this.observer(observedTx, txStatus, receipt);
            removeTxs.push(observedTx.hash);
            return;
          }
        } catch (err) {
          if ((err as any).code === -20) {
            // "Txn Hash not Present"
            console.warn(`tx not found in mempool: ${observedTx.hash}`);
          } else {
            console.warn("error fetching tx state");
            console.error(err);
          }
        }
        if (observedTx.deadline < this.currentBlock) {
          // expired
          console.log(
            `tx exceeded deadline: ${observedTx.deadline}, current: ${this.currentBlock}`
          );
          if (this.observer) this.observer(observedTx, "expired");
          removeTxs.push(observedTx.hash);
        }
      });
      await Promise.all(promises);
      this.observedTxs = this.observedTxs.filter(
        (tx: ObservedTx) => !removeTxs.includes(tx.hash)
      );
      await this.updateBalanceAndNonce();
    } finally {
      release();
    }
  }

  private subscribeToAppChanges() {
    // clear existing subscription, if any
    this.subscription?.stop();
    const subscription =
      this.zilliqa.subscriptionBuilder.buildEventLogSubscriptions(
        this.network_wss,
        {
          addresses: [this.contract_base16],
        }
      );
    subscription.subscribe({ query: MessageType.NEW_BLOCK });
    subscription.emitter.on(StatusType.SUBSCRIBE_EVENT_LOG, (event) => {
      console.log("ws connected: ", event);
    });
    subscription.emitter.on(MessageType.NEW_BLOCK, (event) => {
      // console.log('ws new block: ', JSON.stringify(event, null, 2))
      this.updateBlockHeight().then(() => this.updateObservedTxs());
    });
    subscription.emitter.on(MessageType.EVENT_LOG, (event) => {
      if (!event.value) return;
      // console.log('ws update: ', JSON.stringify(event, null, 2))
      this.updateAppState();
    });
    subscription.emitter.on(MessageType.UNSUBSCRIBE, (event) => {
      console.log("ws disconnected: ", event);
      this.subscription = null;
    });
    subscription.start();
    this.subscription = subscription;
  }

  public getAppState(): AppState {
    if (!this.appState) {
      throw new Error("App state not loaded, call #initialize first.");
    }
    return this.appState;
  }

  public async txWrapUp(
    contract: any,
    transitionName: string,
    args: any,
    params: any,
    toDsBool: any
  ) {
    const tx = await this.callContract(
      contract,
      transitionName,
      args,
      params,
      toDsBool
    );
    if (tx.isRejected()) {
      console.log(JSON.stringify(tx));
      throw new Error("Submitted transaction was rejected.");
    }
    const deadline = this.deadlineBlock();
    const observeTxn = {
      hash: tx.id!,
      deadline,
    };
    await this.observeTx(observeTxn);
    return observeTxn;
  }

  /**
   * Converts an amount to it's unitless representation (integer, no decimals) from it's
   * human representation (with decimals based on token contract, or 12 decimals for ZIL).
   * @param tokenID is the token ID related to the conversion amount, which can be given by either it's symbol (defined in constants.ts),
   * hash (0x...) or bech32 address (zil...). The hash for ZIL is represented by the ZIL_HASH constant.
   * @param amountHuman is the amount as a human string (e.g. 4.2 for 4.2 ZILs) to be converted.
   */
  public toUnitless(tokenID: string, amountHuman: string): string | null {
    const token = this.getTokenDetails(tokenID);
    if (token == null) {
      return null;
    }
    const amountUnitless = new BigNumber(amountHuman).shiftedBy(token.decimals);
    if (!amountUnitless.integerValue().isEqualTo(amountUnitless)) {
      throw new Error(
        `Amount ${amountHuman} for ${token.symbol} has too many decimals, max is ${token.decimals}.`
      );
    }
    return amountUnitless.toString();
  }

  private getTokenDetails(id: string): TokenDetails | null {
    return this.fetchTokenDetails(id);
  }

  private fetchTokenDetails(id: string): TokenDetails | null {
    if (id === "ZIL") {
      return {
        name: "Zilliqa",
        symbol: "ZIL",
        decimals: 12,
      };
    } else {
      return null;
    }
  }

  private unitlessBigNumber = (str: string): BigNumber => {
    const bn = new BigNumber(str);
    if (!bn.integerValue().isEqualTo(bn)) {
      throw new Error(`number ${bn} should be unitless (no decimals).`);
    }
    return bn;
  };

  public async PoolIn(depositAmount: string, stakingContract: String) {
    const poolInAmount = this.unitlessBigNumber(depositAmount);
    const params = {
      amount: new BN(poolInAmount.toString()),
      ...this.txParams(),
    };
    const args: Arguments[] = [
      {
        vname: "stakingContract",
        type: "ByStr20",
        value: String(stakingContract),
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_USER_TRANS.POOL_IN,
      args,
      params,
      true
    );
  }

  public async PoolOut(amount: number) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "amount",
        type: "Uint128",
        value: String(amount),
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_USER_TRANS.POOL_OUT,
      args,
      params,
      true
    );
  }

  public async ClaimMaturedStake() {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_USER_TRANS.CLAIM_MATURED_STAKE,
      args,
      params,
      true
    );
  }

  public async FetchDistributeAddTransferWithdrawRestake(
    doFetchYield: number,
    doDistributeYield: number,
    doOwnershipTransfer: number,
    doWithdrawStake: number,
    doCompoundYield: number,
    stakingContract: String
  ) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "doFetchYield",
        type: "Int32",
        value: String(doFetchYield),
      },
      {
        vname: "doDistributeYield",
        type: "Int32",
        value: String(doDistributeYield),
      },
      {
        vname: "doOwnershipTransfer",
        type: "Int32",
        value: String(doOwnershipTransfer),
      },
      {
        vname: "doWithdrawStake",
        type: "Int32",
        value: String(doWithdrawStake),
      },
      {
        vname: "doCompoundYield",
        type: "Int32",
        value: String(doCompoundYield),
      },
      {
        vname: "stakingContract",
        type: "ByStr20",
        value: String(stakingContract),
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_AUTOMATOR_TRANS
        .FETCH_DISTRIBUTE_ADD_TRANSFER_WITHDRAW_RESTAKE,
      args,
      params,
      true
    );
  }

  public async UnlockContract() {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS.UNLOCK_CONTRACT,
      args,
      params,
      true
    );
  }

  public async UpdateBnumRequired(bnumReqd: Number) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "minBnumRequired",
        type: "Uint128",
        value: String(bnumReqd),
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS.UPDATE_BNUM_REQUIRED,
      args,
      params,
      true
    );
  }

  //  with contract field lastrewardcycle:Uint32 end
  public async UpdateZillionCycleNumber(stakingContract: String) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "stakingContract",
        type: "ByStr20",
        value: String(stakingContract),
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS.UPDATE_ZILLION_CYCLE_NUMBER,
      args,
      params,
      true
    );
  }

  public async whitelistUser(user: String) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "user",
        type: "ByStr20",
        value: String(user),
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS.WHITELIST_USER,
      args,
      params,
      true
    );
  }

  public async updateMaxDeposit(maxDeposit: Number) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "maxDeposit",
        type: "Uint128",
        value: String(maxDeposit),
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS.UPDATE_MAX_DEPOSIT,
      args,
      params,
      true
    );
  }

  public async updateRSCMinStakeAmount(rscMinStakeAmount: Number) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "minStakeAmount",
        type: "Uint128",
        value: String(rscMinStakeAmount),
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS.UPDATE_RSC_MIN_STAKE_AMOUNT,
      args,
      params,
      true
    );
  }

  public async ownerDrainContractBalanceToNewAddress(newAddress: String) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "newAddress",
        type: "ByStr20",
        value: newAddress,
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS
        .OWNER_DRAIN_CONTRACT_BALANCE_TO_NEW_ADDRESS,
      args,
      params,
      true
    );
  }

  public async ownerRequestTransferStakeOwnershipToNewAddress(
    newAddress: String
  ) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "newAddress",
        type: "ByStr20",
        value: newAddress,
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS
        .OWNER_REQUEST_TRANSFER_STAKE_OWNERSHIP_TO_NEW_ADDRESS,
      args,
      params,
      true
    );
  }

  public async ownerConfirmTransferStakeOwnershipFromOldAddress(
    oldAddress: String
  ) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "oldAddress",
        type: "ByStr20",
        value: oldAddress,
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS
        .OWNER_CONFIRM_TRANSFER_STAKE_OWNERSHIP_FROM_OLD_ADDRESS,
      args,
      params,
      true
    );
  }

  public async ownerMigrateFieldsFromOldVerion(mp_autocompounder: String) {
    const params = { amount: new BN(0), ...this.txParams() };
    const args: Arguments[] = [
      {
        vname: "mp_autocompounder",
        type: "ByStr20",
        value: mp_autocompounder,
      },
    ];
    return await this.txWrapUp(
      this.contract,
      this.TRANS.MUSTPOOL_ADMIN_TRANS.OWNER_MIGRATE_FIELDS_FROM_OLD_VERSION,
      args,
      params,
      true
    );
  }
}