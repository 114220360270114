import React, { ReactElement } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import YieldPool from "../YieldPoolNoLottery";

const YieldContainer: React.FC = () => {
  
  const myPools = useSelector((state: RootStateOrAny) => state.data.myPool !== null ? Object.keys(state.data.myPool).map(key => state.data.myPool[key]) : null)
  const mpCompounder = useSelector((state: RootStateOrAny) => state.data.compounder)
  const currentTxBlockRate = useSelector((state: RootStateOrAny) => state.data.blockRate)

  return (
      <div className="flex flex-wrap gap-10">
          {myPools ? myPools.map(({id, ...otherprops}): ReactElement => (
                    <YieldPool
                      mpCompounder={mpCompounder[id]}
                      currentTxBlockRate={currentTxBlockRate}
                      {...otherprops}
                      key={id}
                      myPools={true}
                    />
              ))
            
            : null}
        </div>
  );
};

export default YieldContainer;
