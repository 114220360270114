import { Twitter, GitHub, Send } from "react-feather";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="text-sm text-medium mx-auto">
      <div className="flex justify-between">
        <div className="text-blue-500 font-semibold w-28">
          <img src="/assests/logo.webp" alt="logo" />
        </div>
        <div className="flex justify-end">
          <ul className="flex gap-8">
            <li className="text-gray-400 hover:text-gray-500 cursor-pointer">
              <a
                rel="noreferrer"
                target={"_blank"}
                href={"https://t.me/+MO29oD3omwhjOTJk"}
              >
                <Send size={20} />
              </a>
            </li>
            <li className="text-gray-400 hover:text-gray-500 cursor-pointer">
              <a
                rel="noreferrer"
                target={"_blank"}
                href={"https://twitter.com/must_pool/"}
              >
                <Twitter size={20} />
              </a>
            </li>

            <li className="text-gray-400 hover:text-gray-500 cursor-pointer">
              <a
                rel="noreferrer"
                target={"_blank"}
                href={"https://github.com/MustPool/"}
              >
                <GitHub size={20} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <p className="text-gray-400">© 2022 MustPool. All rights reserved.</p>
        <div>
          <div>
            <ul className="gap-10 flex ">
              <li className="text-gray-500 hover:underline cursor-pointer">
                <Link to={"/disclaimer"}>Disclaimer</Link>
              </li>
              <li className="text-gray-500 hover:underline cursor-pointer">
                <Link to={"/terms"}>Terms</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
