const Disclaimer = () => {
    return (
        <div className="max-w-screen-lg mx-auto font-medium mt-10">
            <div className="mb-10">
            <h1 className="mb-1 text-3xl text-center font-semibold text-sides-03">Disclaimer</h1>
            </div>
            <div className="max-w-3xl mx-auto">
            <div className="mb-6">
                <h2 className="text-lg text-sides-03">No investment advice</h2>
                <p className="text-gray-300">The information provided on this website is not investment advice, trading advice or any other sort of advice and you should not treat any of the website's content as such. Mustpool does not recommend that any cryptocurrency should be bought, sold, or held by you. Do conduct your research and consult your financial advisor before making any investment decisions.</p>
            </div>
            <div className="mb-6">
                <h2 className="text-lg text-sides-03">No endorsements</h2>
                <p className="text-gray-300">Tokens and all other information displayed on Mustpool does not constitute an endorsement, guarantee, warranty or recommendation by Mustpool.</p>
            </div>
    
            <div className="mb-6">
                <h2 className="text-lg text-sides-03">Accuracy</h2>
                <p className="text-gray-300">Mustpool strives to accurately display information, but does not hold any responsibility for wrong or missing information. Information is provided as is, and it should be used at your own risk.</p>
            </div>
            </div>
        </div>
    )
}

export default Disclaimer