export enum ActionTypes {
    FETCH_POOLS_START = 'FETCH_POOLS_START',
    FETCH_POOLS_SUCCESS = 'FETCH_POOLS_SUCCESS',
    FETCH_MYPOOLS_START = 'FETCH_MYPOOLS_START',
    FETCH_MYPOOLS_SUCCESS = 'FETCH_MYPOOLS_SUCCESS',
    FETCH_POOLS_FAILURE = 'FETCH_POOLS_FAILURE',
    FETCH_MYPOOLS_FAILURE = 'FETCH_MYPOOLS_FAILURE',
    FETCH_BLOCK_RATE_SUCCESS = 'FETCH_BLOCK_RATE_SUCCESS',
    FETCH_CURRENT_BLOCK_SUCCESS = 'FETCH_CURRENT_BLOCK_SUCCESS',
    FETCH_CONTRACT_STATE_START = "FETCH_CONTRACT_STATE_START",
    FETCH_B_NUM_SUCCESS = "FETCH_B_NUM_SUCCESS",
    FETCH_USER_WITHDRAW_DICT_SUCCESS = "FETCH_USER_WITHDRAW_DICT_SUCCESS",
    FETCH_INTEREST_DATA_SUCCESS = "FETCH_INTEREST_DATA_SUCCESS",
    FETCH_BACKERS_DEPOSIT_SUCCESS = "FETCH_BACKERS_DEPOSIT_SUCCESS",
    FETCH_USER_LOTTERY_SUCCESS = "FETCH_USER_LOTTERY_SUCCESS",
    FETCH_CONTRACT_STATE_FAILURE = "FETCH_CONTRACT_STATE_FAILURE",
    GET_TRANSACTION_DATA = "GET_TRANSACTION_DATA",
    FETCH_MP_COMPOUNDER_START = 'FETCH_MP_COMPOUNDER_START',
    FETCH_MP_COMPOUNDER_SUCCESS = 'FETCH_MP_COMPOUNDER_SUCCESS',
    FETCH_MP_COMPOUNDER_FAILURE = 'FETCH_MP_COMPOUNDER_FAILURE',
}
