import { WalletUpdateProps } from "./wallet.action"
import { WalletActionTypes } from "./wallet.types"

export const fetchWalletStart = () => ({
    type: WalletActionTypes.FETCH_WALLET_START,
})

export type WalletAction = ReturnType<typeof update>

export function update(payload: WalletUpdateProps) {
    return {
      type: WalletActionTypes.WALLET_UPDATE,
      payload
    }
};