import { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { useDispatch } from 'react-redux'
import { setSelectedValue } from '../../redux/modal/modalActionDispatcher'

const plans = [
  {
    name: '25%',
    value: 25
  },
  {
    name: '50%',
    value: 50
  },
  {
    name: '75%',
    value: 75
  },
  {
    name: 'Max',
    value: 100
  },
]

export default function Max() {
  const [selected, setSelected] = useState(plans[0])
  const dispatch = useDispatch()


  useEffect(() => {
      dispatch(setSelectedValue(selected))
  }, [selected, dispatch])

  return (
    <div className="w-full mt-2 flex justify-end">
      <div className="">
        <RadioGroup value={selected} onChange={setSelected}>
          <div className="space-x-2 flex">
            {plans.map((plan) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan.value}
                className={({ active, checked }) =>
                  `${
                    checked ? 'bg-sky-900 bg-main-03 text-black' : 'bg-gray-700'
                  }
                    relative rounded-md shadow-md px-2.5 py-1.5 cursor-pointer flex focus:outline-none font-medium`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <div className="text-xs">
                          <RadioGroup.Label
                            as="p"
                            className={`${
                              checked ? 'text-black' : 'text-gray-100'
                            }`}
                          >
                            {plan.name}
                          </RadioGroup.Label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}