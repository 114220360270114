import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'

/*
Dropdown component
This component contains Dropdown data and returns the dropdown element
*/

const people = [
  {
    id: 1,
    name: 'Zilliqa',
    avatar:
    '/assests/zilliqa.png',
  },
  {
    id: 2,
    name: 'All Networks',
    avatar:
      '/assests/sushi.png',
  },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function Dropdown() {
  const [selected, setSelected] = useState(people[0])
  const [hover, setHover] = useState(false)

  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative">
        <Listbox.Button className="relative w-64 bg-main-01 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none text-white font-medium text-sm">
          {selected.name === "All Networks" ? 
              <div onMouseLeave={() => setHover(false)} onMouseEnter={() => setHover(true)} className="flex items-center">
                <div className={`${ hover ? "gap-3" : "gap-0" } flex transition-all `} >
                  <img src={"/assests/eth.png"} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                  <img src={"/assests/zilliqa.png"} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                  <img src={"https://assets.coingecko.com/coins/images/10365/small/near_icon.png?1601359077"} alt="" className="filter invert flex-shrink-0 h-6 w-6 rounded-full" />
                </div>
                <span className={classNames(selected ? 'font-semibold' : 'font-medium', 'ml-3 block truncate')}>
                  {selected.name}
                </span>
              </div> 
              : 
              <span>
                <span className="flex items-center">
                <img src={selected.avatar} alt="" className={`${selected.name === "HBAR" || selected.name === "Near Protocol" ? "  filter invert " : ""} flex-shrink-0 h-6 w-6 rounded-full`} />
                  <span className="ml-3 block truncate">{selected.name}</span>
                </span>
                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                </span>
              </span>
          }
        </Listbox.Button>

        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-main-01 shadow-lg max-h-56 rounded-md py-1 font-medium ring-1 ring-black ring-opacity-5 overflow-auto text-sm">
            {people.map((person) => (
              <Listbox.Option
                key={person.id}
                className={({ active }) =>
                  classNames(
                    active ? 'text-gray-300 bg-sides-00' : 'text-white',
                    'cursor-default select-none relative py-2 pl-3 pr-9'
                  )
                }
                value={person}
              >
                {({ selected, active }) => (
                  <>
                  {person.name === "All Networks" ? 
                    <div className="flex items-center">
                      <div className="gap-0 flex" >
                        <img src={"/assests/eth.png"} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                        <img src={"/assests/zilliqa.png"} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                        <img src={"https://assets.coingecko.com/coins/images/10365/small/near_icon.png?1601359077"} alt="" className="filter invert flex-shrink-0 h-6 w-6 rounded-full" />
                      </div>
                      <span className={classNames(selected ? 'font-semibold' : 'font-medium', 'ml-3 block truncate')}>
                        {person.name}
                      </span>
                    </div> 
                    : 
                    <div className="flex items-center">
                      <img src={person.avatar} alt="" className={`${person.name === "HBAR" || person.name === "Near Protocol" ? "  filter invert " : ""} flex-shrink-0 h-6 w-6 rounded-full`} />
                      <span className={classNames(selected ? 'font-semibold' : 'font-medium', 'ml-3 block truncate')}>
                        {person.name}
                      </span>
                    </div>}
                    
                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-indigo-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4'
                        )}
                      >
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}