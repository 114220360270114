import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectMenuOpen } from "../../redux/menu/menu.selector";
import SidebarElements from "./sidebar.component";
import "./sidebar.css"

/*
Sidebar Component
This component shows contains the sidebar data and presents the full view of the sidebar .
*/

type props = {
  menuOpen: boolean
}

const Sidebar: React.FC<props> = ({menuOpen}) => {

    const sideBarData = [
        {
          text: "Pools",
          svg:  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>,
          color: "text-neon-cyan",
          route: "/yields",
          id: 1
        },
        {
          text: "FAQ",
          svg:  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                </svg>,
          color: "text-neon-cyan",
          route: "/faq",
          id: 2
        },
      ];

    return (
      <div className="z-50">
        <div className={`animated-sidebar absolute ${menuOpen ? "fadeIn-sidebar " : "fadeOut-sidebar "} `} >
            <div className="bg-black lg:hidden block text-white opacity-40 w-screen h-screen z-40 absolute" ></div>
              <div className="w-64 pt-2 bg-main-00 absolute z-50 shadow-2xl font-semibold h-screen">
                {sideBarData.map((sidebar) => (
                    <SidebarElements key={sidebar.id} {...sidebar} menuOpen={true} />
            ))}
            </div>
        </div>
        <div style={{background: "linear-gradient(180deg, rgba(11,22,30,1) 0%, rgba(12,23,31,1) 55%, rgba(12,23,31,0.6) 90%, rgba(12,23,31,0.1) 100%)"}} className="lg:block hidden w-64 pt-2 -ml-3 font-semibold h-screen">
            {sideBarData.map((sidebar) => (
                    <SidebarElements key={sidebar.id} {...sidebar} />
        ))}
      </div>
    </div>
     );
}

const mapStateToProps = createStructuredSelector({
  menuOpen: selectMenuOpen
})
 
export default connect(mapStateToProps) (Sidebar);