import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
//import Timer from "../../Timer/timer";
import DepositModalComponent from "../../ModalComponents/depositModal.component";
import "./loader.css"
import { WalletButton } from "../../customButton/walletButton"
import toast from "react-hot-toast";
import { fetchUserDeposit } from "../../../utils/fetchUserDeposit";
import NetworkBadge from "../../../utils/networkBadge";

type PathParamsType = {
  param: string,
}

type PropsType = RouteComponentProps<PathParamsType> & {
  currentTxBlockRate: any,
  contractName: string,
  asset: string,
  routeName: string,
  govTokenApy: string,
  poolIndex: number,
  assetIcon: string,
  baseAssetApy: string,
  mpCompounder: any,
  myPools?: boolean
  liquidity: string
}

const YieldPool: React.FC<PropsType> = ({
  mpCompounder,
  contractName,
  asset,
  routeName,
  match,
  history,
  poolIndex,
  assetIcon,
  baseAssetApy,
  myPools,
  liquidity
}) => {

  //const secsLeft = Math.ceil(blocksToNextDraw / currentTxBlockRate);

  const [disableClick, setDisableClick] = useState(false)
  const [stakingContactLocal, setLocalAppState] = useState({});
  const [userBalance, setUserBalance] = useState<string | null>(null)
  //const [totalPoolLiquidity, setTotalPoolLiquidity] = useState<number>(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState<null | object>(null)

  const wallet = useSelector((state: any) => state.wallet.wallet)

  useEffect(() => {
    const depositData = {stakingContactLocal, mpCompounder, asset, contractName }

    if (modalOpen) {
      setModalData(depositData)
    } else {
      setModalData(null)
    }
  }, [modalOpen,stakingContactLocal, mpCompounder, asset, contractName])

  const pushRoute = () => {
    if (wallet == null) {
      toast.error("Please connect your wallet")
    } else {
      history.push(`${match.path}${myPools ? '/my-pools' : '/all-pools'}/${routeName}`)
    }
  }  

  useEffect(() => {
      const get_local_state = (mpCompounder: any) => {
        if ((mpCompounder !== undefined) && (wallet !== null)) {
          const base16 = wallet.addressInfo.byte20      
          const intialAppState = mpCompounder.appState;
          const staking_contract_local = intialAppState["contractState"]["staking_contract"]
          setUserBalance(fetchUserDeposit(intialAppState, base16).toFixed(2))
          return staking_contract_local
        }
      }

      const stakingContractLocal = get_local_state(mpCompounder)
      setLocalAppState(stakingContractLocal)
  }, [mpCompounder, wallet])

  return (
    <div className="sm:max-w-screen-lg sm:mx-0 max-w-xs lg:min-w-card w-full md:w-5/12" >
      <NetworkBadge />
      {(modalData !== null && modalOpen === true) ? <DepositModalComponent closeModal={setModalOpen} modalData={modalData} openModal={modalOpen} /> : null } 
      <div onClick={() => disableClick ? null : pushRoute()} style={{boxShadow: "-3px 1px 25px 5px rgba(11, 22, 30, 0.80)"}} className="hover:border-main-02 hover:bg-sides-01 bg-main-01 border-transparent border-2 flex items-center justify-center w-full transition-all cursor-pointer py-8 rounded-md text-white font-semibold">
        <div className="w-full px-6 mx-auto md:px-10" >
          <div>
            <div className="sm:flex md:block lg:flex justify-between mb-5" >
              <div className="flex gap-5" >
                <div className="flex" >
                  <div className="bg-sides-02 w-10 relative z-10 h-10 flex items-center justify-center rounded-full">
                    <img className="w-6" src={assetIcon} alt="Assest Icon" />
                  </div>
                  <div className="bg-main-00 relative z-0 -ml-3 w-10 h-10 flex items-center justify-center rounded-full"></div>
                </div>
                <div>
                  <div className="rounded-sm bg-sides-03 text-xs w-14 py-0.5 mb-1 flex justify-center items-center"><p>Pool <span className="ml-1">#{poolIndex}</span></p></div>
                  <h1 className="md:text-xl text-lg mb-2">{contractName}<span className="text-main-02 text-sm ml-2" >{asset}</span></h1>
                </div>
              </div>
              <div className="" onMouseEnter={() => setDisableClick(true)} onMouseLeave={() => setDisableClick(false)} >
                    <WalletButton onClick={() => setModalOpen(!modalOpen)}></WalletButton>
              </div>
            </div>
            <div className="sm:flex md:block lg:flex items-center gap-10 text-sm block text-gray-400 font-medium" >
                <div className="">
                  <p className="mb-2 uppercase text-sides-03">Apy</p>
                  <p className="text-sm uppercase text-white" >{baseAssetApy}</p>
                </div>   
                <div className="" >
                    <p className="mb-2 uppercase text-sides-03">Pool Liquidity</p>
                    <p className="text-sm uppercase text-white" >{liquidity}</p>
                </div>
                {userBalance && myPools ?
                <div className="sm:flex items-center gap-10">
                  <div className="sm:h-10 opacity-90 bg-main-03 my-4 h-px w-full sm:my-0 sm:w-px rounded-xl"></div>
                  <div className="uppercase font-medium">
                      <p className="mb-2 text-sides-03">My Liquidity</p>
                      <p className="text-sm text-white" >{userBalance} Zil</p>
                  </div>
                </div>
                : null}
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default withRouter(YieldPool);