import { poolDataSaga } from "./data/data.saga";
import { transactionSagas } from "./transaction/transaction.sagas"
import {StartWalletSagas, RestartMpCompounder } from "./wallet/wallet.saga";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

export function startSagas() {
    sagaMiddleware.run(poolDataSaga);
    sagaMiddleware.run(transactionSagas);
    sagaMiddleware.run(StartWalletSagas);
    sagaMiddleware.run(RestartMpCompounder);
};
  
export default sagaMiddleware;