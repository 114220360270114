import { ActionTypes } from "./modalTypes"; 
import { Action } from "./modal.action";

const INITIAL_STATE = {
  depositModal: false,
  depositData: null,
  selectedValue: 0,
  withdrawModal: false,
  interestModal: false,
};

const modalReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ActionTypes.DEPOSIT_MODAL_OPEN:
      return {
      ...state,
      depositModal: action.payload.depositModal,
      depositData: action.payload.depositData
    }
    case ActionTypes.WITHDRAW_MODAL_OPEN:
      return {
      ...state,
      withdrawModal: !state.withdrawModal
      }
    case ActionTypes.INTEREST_MODAL_OPEN:
      return {
      ...state,
      interestModal: !state.interestModal
    }
    case ActionTypes.SET_SELECTED_VALUE:
      return {
        ...state,
        selectedValue: action.payload
      }
    default:
      return state;
  }
};

export default modalReducer;