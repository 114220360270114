import { useEffect, useState } from "react"
import { RootStateOrAny, useSelector } from "react-redux"
import CustomButton from "./button"

export const WalletButton = ({onClick}: any) => {
    const wallet = useSelector((state: RootStateOrAny) => state.wallet.wallet)
    let mpCompounder = useSelector((state: RootStateOrAny) => state.data.compounder)
    if (mpCompounder === undefined) {
      mpCompounder = []
    }
    const [loading, setLoading] = useState("Connect your Wallet")

    useEffect(() => {
        if (wallet === null) {
          setLoading("Connect your Wallet")
        } if ((wallet !== null) && (mpCompounder.length === 0)) {
          setLoading("loader")
        } else if ((wallet !== null) && typeof(mpCompounder) === "object") {
          setLoading("Deposit into pool")
        }
      }, [mpCompounder, wallet])
      
      return (
            <CustomButton disabled={loading === "Connect your Wallet" ? true : false} onClick={onClick}>{loading === "loader" ? <div className="lds-ring"><div className="black"></div></div> : loading}</CustomButton>
      )
}