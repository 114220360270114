import { RootStateOrAny, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PoolPage from "../components/Pools/pools-page/poolPage.container";

const YieldPage: React.FC = () => {

  const { poolId }: any = useParams()

  const poolData = useSelector((state: RootStateOrAny) => state.data.pool[poolId])
  const mpCompounder = useSelector((state: RootStateOrAny) => state.data.compounder[poolId])
  const currentTxBlockRate = useSelector((state: RootStateOrAny) => state.data.currentTxBlockRate)

    return (
        <div className=" max-w-screen-lg mx-auto mt-5" >
          <PoolPage {...poolData} mpCompounder={mpCompounder} currentTxBlockRate={currentTxBlockRate} /> 
        </div>
     );
}
 
export default YieldPage