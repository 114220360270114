import { WalletProvider } from '../../sdk/autocompounder/index';
import { Network } from '../../sdk/utils/constants';
import { ConnectWalletResult } from "./connectedWallet";
import { ZilPayConnectedWallet } from "./zilpayWallet";

export const ZilPayNetworkMap = {
    mainnet: Network.mainnet,
    testnet: Network.testnet,
  } as { [index: string]: Network };

export const connectWalletZilPay = async (zilPay: any): Promise<ConnectWalletResult> => {

    if (!zilPay.wallet.isConnect)
      throw new Error("ZilPay connection failed.");
  
    const account: any = zilPay.wallet.defaultAccount;
    if (!account)
      throw new Error("Please sign in to your ZilPay account before connecting.");
  
    const net = zilPay.wallet.net;
    const network = ZilPayNetworkMap[net];
    if (!network)
      throw new Error(`Unsupported network for ZilPay: ${net}`);
  
    const wallet = new ZilPayConnectedWallet({
      network,
      zilpay: zilPay as WalletProvider,
      bech32: account!.bech32,
      base16: account!.base16,
    });
  
    return { wallet };
};

export const fetchUserBalance = async (zilPay: any) => {
  const bech32 = zilPay.wallet.defaultAccount['bech32']
  const { result } = await zilPay.blockchain.getBalance(bech32)
  return parseInt(result.balance) / Math.pow(10, 12)
}