const Terms = () => {
    return (
        <div className="max-w-screen-lg mx-auto font-medium mt-10 text-gray-300">
            <div className="py-8 text-center">
                <h1 className="mb-1 text-sides-03 text-3xl">Mustpool Terms and Conditions</h1>
                <p>Last modified at: April 3th, 2021.</p>
            </div>
            <div className="max-w-4xl mx-auto text-sm">
                <div className="mb-6">
                <p className="mb-4">The following terms and conditions (the “Terms of Service” or “Agreement”) constitutes the entire agreement between you (“User”, “You” or “Your”) and Mustpool (“Mustpool”, "The Company", “Us”, “Our”, or ‘We”).</p>
                
                <p className="mb-4">Mustpool is the owner and operator of the Mustpool.com (the "Website"). We offer the information and services (individually and collectively the "Services) available through the Website upon your acceptance of these Terms of Service and other posted notices and agreements.</p>
                
                <p className="mb-4">By accessing or using our Website (or any of our Services), singing up for any of our services or products in any way, you hereby accept these Terms and Conditions, our Privacy Policy, Listing requirements, as well as any other terms of service (including rules and guidelines) that may be implemented from time to time to relation to our Website.</p>
                
                <p>Mustpool, in its sole and absolute discretion, may make changes or modifications to the Website or the Terms and Conditions, at any time and without prior notice to You, and such changes or modifications shall be effective immediately upon posting to the Website. You acknowledge and agree that (i) We may notifiy You of such changes or modifications by posting them to the Website and (ii) Your use of the Website or the Services after such changes or modifications have been made shall constitute Your acceptance of all posted modifications and changes.</p>
                </div>
                <div className="mb-6">
                <h2 className="text-lg text-sides-03">Access to Services</h2>
                <p className="mb-4">The Company makes no representation or warranty that the Website may be lawfully accessed in any specific location. Access to the Website may not be legal by certain persons or in certain states or certain countries, or may require government authorization or registration. When You access the Website, You are solely responsible for compliance with the laws and regulations of Your jurisdiction.</p>

                <p className="mb-4">Any users accessing the Website or purchasing Services must be at least eighteen (18) years of age.</p>

                <p>THE COMPANY RESERVES THE RIGHT TO DELETE UPLOADED CONTENT AND TO RESTRICT OR TERMINATE YOUR ACCESS TO THE WEBSITE AT ANY TIME AND IN ITS SOLE DISCRETION, WITHOUT PRIOR NOTICE, WHENEVER THE COMPANY DEEMS THAT YOUR USE IS IN ANY MANNER INAPPROPRIATE OR IN VIOLATION OF APPLICABLE LAWS AND REGULATIONS OR THESE TERMS OF SERVICE.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">Use of Services</h2>
                <p className="mb-4">You are solely responsible for deciding whether the Services offered on the Website are suitable for Your own purposes, and whether the Services match Your needs. You are solely responsible for the information you input or upload to the Website, and represent and warrant that You have the right and authorization to input or upload all such information, and that all such information is current and accurate, and will be kept up-to-date.</p>

                <p className="mb-4">If You represent a business entity, You represent and warrant that You have authority to access the Services and Website on behalf of that business entity, that the business entity is responsible for Your use of the Website in accordance with the Terms of Services and other relevant documents, and that the Company will be liable for any violations relating to Your use of the Website.</p>

                <p className="mb-4">The Company offers no express or implied guarantees or warranties regarding the benefits from using the Services, if any, or that You will find the Services satisfactory, beneficial or suitable for Your own circumstances.</p>

                <p>You acknowledge and agree that the Company is not providing any legal, financial, investment or accounting advice.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">Social Media</h2>
                <p className="mb-4">Mustpool communicates through different social media pages and communication mediums, including but not limited to, Facebook, Instagram, Twitter, and Telegram.</p>

                <p className="mb-4">Any posts made on these social media pages are not necessarily the views or opinions of Mustpool, or its Affiliates. Use of such third-party social media and communication platforms are governed by the terms and conditions of the applicable social media outlet.</p>

                <p>Users may have the option to use Twitter, Facebook or other social networking platforms through the Website to share links and content. Users undertake this option as their sole risk and responsibility, including but not limited to, complying with all of the Terms of Use of the social networking platform.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">Intellectual Property</h2>
                <p className="mb-4">"Mustpool", and any other Company trademarks and trade names, and any variations thereof, are and shall remain the trademarks and trade names and exclusive property of the Company, and any unauthorized use of such trademarks and trade names is unlawful.</p>

                <p className="mb-4">The Website, including without limitation all research, programs, newsletters, social media activities, compiled binaries, interface layout, interface text, documentation, resources and graphics, are the Company’s sole and exclusive property and are protected by copyright, trademark, and other laws of the United States and other countries.</p>

                <p className="mb-4">You may not sell or modify the Website content or the Services, or reproduce, display, publicly perform, distribute, or otherwise use the Website content or the Services in any manner or for any purpose.</p>

                <p className="mb-4">The Company shall own all rights, titles and interest (including patent rights, copyrights, trade secret rights, mask work rights, trademark rights, sui generis database rights and all other rights of any sort throughout the world) relating to any and all inventions (whether or not patentable), works of authorship, mask works, modifications, feature improvements, suggestions, designations, designs, know-how, ideas and information made or conceived or reduced to practice, in whole or in part, by the User or any Author (Collectively referred to as Author for the purposes of this section) created or published in connection with their Services or any Proprietary Information (as defined below) (collectively as the “Inventions”) and Author will promptly disclose and provide all Inventions to the Company . All Inventions are works made for hire to the extent allowed by law. In addition, if any Invention does not qualify as a work made for hire, Author hereby makes all assignments necessary to accomplish the foregoing ownership. Author shall further assist the Company, at the Company’s expense, to further evidence, record and perfect such assignments, and to perfect, obtain, maintain, enforce, and defend any rights assigned. Author hereby irrevocably designates and appoints the Company and its Affiliates as attorneys-in-fact to act for and in Author’s behalf to execute and file any document and to do all other lawfully permitted acts to further the foregoing with the same legal force and effect as if executed by Author.</p>

                <p className="mb-4">If any part of the Services or Inventions is based on, incorporates, or is an improvement or derivative of, or cannot be reasonably and fully made, used, reproduced, distributed or otherwise exploited without using or violating technology or intellectual property rights owned or licensed by Author and not assigned hereunder, Author hereby grants the Company, its Affiliates, and its successors a perpetual, irrevocable, worldwide royalty-free, non-exclusive, sub-licensable right and license to exploit and exercise all such technology and intellectual property rights in support of the Company’s exercise or exploitation of the Services, Inventions, other work performed hereunder, or any assigned rights (including any modifications, improvements and derivatives of any of them).</p>

                <p>Author agrees that all Inventions and all other business, technical and financial information (including, without limitation, the identity of and information relating to customers or employees) Author develops, learns or obtains in connection with Services or that are received by or for the Company in confidence, constitute “Proprietary Information.” Author will hold in confidence and not disclose or, except in performing the Services, use any Proprietary Information. However, Author shall not be obligated under this paragraph with respect to information Author can document is or becomes readily publicly available without restriction through no fault of Author. Upon termination and as otherwise requested by the Company, Author will promptly return to the Company all items and copies containing or embodying Proprietary Information, except that Author may keep its personal copies of its compensation records and this Agreement. Author further agrees that any property situated on the Company’s premises and owned, leased or otherwise possessed by the Company , including computers, computer files, email, voicemail, storage media, filing cabinets or other work areas, is subject to inspection by Company personnel at any time with or without notice.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">User Comments and Suggestions</h2>
                <p className="mb-4">While Your feedback is valued, please understand that if You send creative ideas, inventions, or suggestions (for the purposes of this section, “Submissions”), all such Submissions shall be, upon transmission to Us by You, the sole and exclusive property of the Company. The Company shall own exclusively all now known or later discovered rights to the Submissions and shall be entitled to unrestricted use of the Submissions for any purpose whatsoever, commercial or otherwise, without compensation to you or any other third party.</p>
                <p>No part of the Submissions shall be subject to any obligation of confidence to You by the Company, and the Company shall not be liable to You for any use or disclosure.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">Links</h2>
                <p className="mb-4">The Website may contain links, including payment links, to other websites and/or advertisements that take a user to another website. The Company does not endorse and is not responsible for the content, Terms of Service, or privacy policies of any such websites. Any use of these other websites is at Your sole risk.</p>
                <p>BY USING THE Mustpool, YOU AGREE TO INDEMNIFY, DEFEND, AND FOREVER HOLD HARMLESS THE COMPANY FOR ANY CAUSE OF ACTION WHATSOEVER ARISING FROM YOUR USE OF THE SITE OR EXTERNAL LINKS, WHETHER IN TORT, CONTRACT, OR OTHERWISE.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">General Disclosures</h2>
                <p className="mb-4">Research or information on the Website is not an offer to sell or the solicitation of an offer to buy any security or cryptocurrency in any jurisdiction where such an offer or solicitation would be illegal. It does not constitute a personal recommendation to You or take into account the particular investment objectives, financial situations, or needs of individual users. You should consider whether any perceived advice or recommendation in this research or information is suitable for Your particular circumstances and, if appropriate, seek professional advice, including tax or legal advice.</p>
                <p>The price and value of potential cryptocurrency investments referred to in this research or information and the income from them may fluctuate. Past performance is not a guide to future performance, future returns are not guaranteed, and a loss of all original capital may occur. Fluctuations in exchange rates could have adverse effects on the value or price of, or income derived from, certain investments.</p>
                <p>Certain transactions, including those involving futures, options, and other derivatives, give rise to substantial risk and are not suitable for all consumers.</p>
                <p>We and our Affiliates will, from time to time, have long or short positions in, act as principal in, and buy or sell, the cryptocurrencies, securities or derivatives, if any, referred to in this research.</p>
                <p>Any third party referenced herein, including any salespeople, traders and other professionals or members of their household, may have positions in the products mentioned that are inconsistent with the views expressed by analysts named in research or information contained on the Website.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">Token Listings</h2>
                <p className="mb-4">Any listing, reference, link, commercial, advertisement, or promotion of any token is not an endorsement of the token by the Company of the fitness or viability of anytoken offering.</p>
                <p>Tokens listed on the Website are listed at the Company’s sole discretion. The issuer of each listed token must contact the Company directly and provide all information required by the Company. The issuer of each listed token is solely responsible for the accuracy of all information provided by the Company on the Website relating to a token listing.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">NO WARRANTIES/DISCLAIMERS</h2>
                <p className="mb-4">THE SERVICES AND WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. YOUR USE OF THE SERVICES AND WEBSITE IS AT YOUR SOLE RISK. THE COMPANY MAKES NO WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS OR TIMELINESS OF THE SERVICES OFFERED OR ANY OTHER CONTENT ACCESSED THROUGH THE WEBSITE.</p>

                <p className="mb-4">THE COMPANY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTY OF MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>

                <p className="mb-4">THE COMPANY DOES NOT WARRANT THAT THE WEBSITE WILL OPERATE ERROR-FREE OR THAT THE WEBSITE IS FREE OF COMPUTER VIRUSES OR OTHER HARMFUL MECHANISMS. THE COMPANY IS NOT RESPONSIBLE FOR COSTS ASSOCIATED WITH THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA.</p>

                <p className="mb-4">ALTHOUGH THE INTENT IS TO PROVIDE ACCURATE AND TIMELY INFORMATION, THE CONTENT AND INFORMATION PROVIDED MAY NOT ALWAYS BE ENTIRELY ACCURATE, COMPLETE OR CURRENT AND MAY ALSO INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS. CONTENT INFORMATION MAY BE CHANGED OR UPDATED FROM TIME TO TIME WITHOUT NOTICE, INCLUDING WITHOUT LIMITATION INFORMATION REGARDING OUR POLICIES, PRODUCTS AND SERVICES. ACCORDINGLY, YOU SHOULD VERIFY ALL INFORMATION BEFORE RELYING ON IT, IF AT ALL, AND ALL DECISIONS BASED ON INFORMATION CONTAINED ON THE WEBSITE ARE YOUR SOLE RESPONSIBILITY AND THE COMPANY SHALL HAVE NO LIABILITY FOR SUCH DECISIONS. LINKS TO THIRD-PARTY MATERIALS (INCLUDING WITHOUT LIMITATION WEBSITES) MAY BE PROVIDED AS A CONVENIENCE BUT ARE NOT CONTROLLED BY US. YOU ACKNOWLEDGE AND AGREE THAT WE ARE NOT RESPONSIBLE FOR ANY ASPECT OF THE INFORMATION, CONTENT, OR SERVICES CONTAINED IN ANY THIRD-PARTY MATERIALS OR ON ANY THIRD-PARTY SITES ACCESSIBLE OR LINKED TO THE WEBSITE.</p>

                <p className="mb-4">THE TRANSMISSION OF DATA OR INFORMATION INCLUDING COMMUNICATIONS BY E-MAIL OVER THE INTERNET OR OTHER PUBLICLY ACCESSIBLE NETWORKS IS NOT SECURE, AND IS SUBJECT TO POSSIBLE LOSS, INTERCEPTION OR ALTERATION WHILE IN TRANSIT. ACCORDINGLY, THE COMPANY DOES NOT ASSUME ANY LIABILITY FOR ANY DAMAGE YOU MAY EXPERIENCE OR COSTS YOU MAY INCUR AS A RESULT OF ANY TRANSMISSIONS OVER THE INTERNET OR OTHER PUBLICLY ACCESSIBLE NETWORKS, SUCH AS TRANSMISSIONS INVOLVING THE EXCHANGE OF E-MAIL. WHILE THE COMPANY TAKES COMMERCIALLY REASONABLE EFFORTS TO SAFEGUARD THE PRIVACY OF THE INFORMATION YOU PROVIDE AND TREAT SUCH INFORMATION IN ACCORDANCE WITH OUR PRIVACY POLICY, IN NO EVENT WILL THE INFORMATION YOU PROVIDE BE DEEMED TO BE CONFIDENTIAL, CREATE ANY FIDUCIARY OBLIGATIONS ON THE COMPANY’S PART, OR RESULT IN ANY LIABILITY TO YOU IN THE EVENT THAT SUCH INFORMATION IS INADVERTENTLY RELEASED BY US OR ACCESSED BY THIRD PARTIES WITHOUT CONSENT.</p>

                <p>THE COMPANY TAKES NO RESPONSIBILITY WHATSOEVER FOR THE INFORMATION YOU HAVE UPLOADED TO THE WEBSITE AND SHALL NOT BE RESPONSIBLE OR LIABLE FOR THE DELETION, CORRECTION, DESTRUCTION, DAMAGE, OR LOSS OF SUCH INFORMATION, OR FAILURE TO STORE ANY OF SUCH INFORMATION. NOR IS THE COMPANY RESPONSIBLE FOR LOSS OF INFORMATION THROUGH THE ACTION OF ANY THIRD PARTY OR BECAUSE OF CIRCUMSTANCES BEYOND THE COMPANY’S CONTROL. ALL USERS ARE EXPECTED TO HAVE THEIR OWN BACKUP OF ALL OF THEIR INFORMATION.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">LIMITATION OF LIABILITY</h2>
                <p className="mb-4">IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE D1542, WHICH SAYS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."</p>

                <p className="mb-4">IN ADDITION TO ANY LIMITATION OF LIABILITY SET FORTH HEREIN, TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, YOU HEREBY UNDERSTAND AND AGREE THAT NEITHER THE COMPANY NOR ITS SUBSIDIARIES, AFFILIATES, PARTNERS, OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES OR AGENTS SHALL BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR ANY OTHER DAMAGES RELATING TO OR RESULTING FROM YOUR USE OR INABILITY TO USE THE WEBSITE OR ACCESS THE SERVICES OR FROM ANY ACTIONS THE COMPANY TAKES OR FAILS TO TAKE, WHETHER OR NOT THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE INCLUDE DAMAGES FOR ERRORS, OMISSIONS, INTERRUPTIONS, DEFECTS, DELAYS, COMPUTER VIRUSES, LOSS OF PROFITS, LOSS OF DATA, UNAUTHORIZED ACCESS TO AND ALTERATION OF TRANSMISSIONS AND DATA, BODILY INJURY, EMOTIONAL DISTRESS AND OTHER TANGIBLE AND INTANGIBLE LOSSES.</p>

                <p>THIS LIMITATION APPLIES REGARDLESS OF WHETHER THE DAMAGES ARE CLAIMED UNDER THE TERMS OF A CONTRACT, AS THE RESULT OF NEGLIGENCE OR OTHERWISE, AND EVEN IF WE OR OUR REPRESENTATIVES HAVE BEEN NEGLIGENT OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. COMPANY'S MAXIMUM LIABILITY ARISING OUT OF OR IN CONNECTION WITH THIS WEBSITE OR YOUR USE OF THE SERVICES, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY OR OTHERWISE), WILL NOT EXCEED THE AMOUNT, IF ANY, PAID BY YOU FOR THE SERVICES.</p>
                </div>

                <div className="mb-6">
                <h2 className="text-lg text-sides-03">YOUR LIABILITY</h2>
                <p className="mb-4">IF YOU CAUSE A TECHNICAL DISRUPTION TO THE SERVICES OR TO OTHERS YOU AGREE TO BE RESPONSIBLE FOR ANY AND ALL LIABILITIES, COSTS AND EXPENSES (INCLUDING ATTORNEY'S FEES) ARISING FROM THAT DISRUPTION.</p>
                <p>YOU HEREBY AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS THE COMPANY AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, AND PARTNERS, HARMLESS FROM AND AGAINST ANY LOSS, LIABILITY, CLAIM, ACTION, OR DEMAND, INCLUDING WITHOUT LIMITATION REASONABLE LEGAL AND ACCOUNTING FEES, ALLEGING OR RESULTING FROM (I) YOUR USE OF THE SERVICES; (II) ANY MATERIAL YOU PROVIDE TO THE SERVICES, (III) ANY SERVICES THAT YOU USE OR (IV) YOUR BREACH OF THE TERMS OF THIS AGREEMENT AND YOUR REPRESENTATIONS AND WARRANTIES. THE COMPANY SHALL PROVIDE NOTICE TO YOU PROMPTLY OF ANY SUCH CLAIM, SUIT, OR PROCEEDING AND SHALL ASSIST YOU, AT YOUR EXPENSE, IN DEFENDING ANY SUCH CLAIM, SUIT OR PROCEEDING. THE COMPANY RESERVES THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER SUBJECT TO IMMEDIATE INDEMNIFICATION BY YOU.</p>
                </div>
            </div>
        </div>
    )
}

export default Terms