import "./withSpinner.css"

type props = {
  isLoading: boolean,
  otherProps: any
}

const WithSpinner = (WrappedComponent: React.ComponentType) => {
  const Spinner: React.FC<props> = ({ isLoading, ...otherProps }) => {
    return isLoading ? (
      <div className="spinner-overlay">
        <div className="spinner-container" >
          <img className="w-10" src="/assests/loader.webp" alt="logo" />
        </div>
      </div>
    ) : (
      <WrappedComponent {...otherProps} />
    );
  };
  return Spinner;
};

export default WithSpinner;