import { WalletProvider } from "../../sdk/autocompounder/index";
import { Network } from "../../sdk/utils/constants";
import { ConnectedWallet, WalletAccountInfo, WalletConnectType } from "./connectedWallet";

export interface ZilPayConnectProps {
  zilpay: WalletProvider;
  network: Network;
  bech32: string;
  base16: string;
};
export class ZilPayConnectedWallet implements ConnectedWallet {
  type = WalletConnectType.ZilPay;
  provider: WalletProvider;
  network: Network;
  addressInfo: WalletAccountInfo;

  constructor(props: ZilPayConnectProps) {
    this.provider = props.zilpay;
    this.network = props.network;
    this.addressInfo = {
      bech32: props.bech32,
      byte20: props.base16,
    };
  }
}