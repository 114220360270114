
import toast from "react-hot-toast";
import decodeMessage from "./decode";
import { APIS, Network } from "../../sdk/utils/constants";
import { Zilliqa } from "@zilliqa-js/zilliqa";

/*
Creates a toast using react-hot-toast,
Uses ZilPay to subscribe to transactions,
Updates toast with status of transaction,
Shows message as per messages codes.
*/

export const transitionMessageAlert = (
    {transactionHash}: any,
) => {
    const {hash,  mpCompounder} = transactionHash

    const net: Network = mpCompounder.walletProvider.wallet.net
    const zilliqa = new Zilliqa(APIS[net])

    const transition = new Promise<string>((success, error) => {
        const check = async () => {
          if ((await mpCompounder.getObservedTxs(hash)).length === 0) {
            const Tx = await zilliqa.blockchain.getTransaction(hash);
            const code = Tx.status
            console.log({code})
            const message = decodeMessage(code);
            if (message.type === "success") success(message.alert);
            error(message.alert);
          } else {
            setTimeout(check, 500);
          }
        };
        check();
      });
      
      
    toast.promise(transition, {
        loading: "loading",
        success: (message: string) => message,
        error: (message: string) => message,
    });

    return transition
};