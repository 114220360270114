import { connect, useDispatch, useSelector } from "react-redux";
import CustomButton from "../../customButton/button";
import { selectFilteredBackersDeposit, selectFilteredData, selectFilteredUserLottery } from "../../../redux/data/data.selectors";
import { withdrawOpen } from "../../../redux/modal/modalActionDispatcher";
import WithdrawModalComponent from "../../ModalComponents/withdrawModal.component";
import DepositModalComponent from "../../ModalComponents/depositModal.component";
//import UserLottery from "../../userLottery/userLottery";
import { claimStakeFunctionStart } from "../../../redux/transaction/transaction.actionDispatcher";
import "./table.css"
import { useEffect, useState } from "react";

type props = {
    interestInfo: number,
    userLottery: string,
    claimMaturedStake: string,
    asset: string,
    contractName: string,
    mpCompounder: object
  }

const UserTranaction: React.FC<props> = ({
    asset, 
    contractName,
    mpCompounder
    }) => {

    const {backersDeposit, userDict } = useSelector((state: any) => state.data)
    const [withdrawAmount, setWithdrawalAmount] = useState(0.000)
    const [stakingContactLocal, setLocalAppState] = useState({});
    //const [claimAvailableStake, setClaimAvailableStake] = useState(false);

    useEffect(() => {

        if (userDict !== null) {
            let withdraw = 0.000
            userDict.map(({progress_per, user_withdraw_amount}: any) => {
                if (progress_per > 100) {
                    return withdraw += parseInt(user_withdraw_amount)/1000000000000
                } else return withdraw
            })
            setWithdrawalAmount(withdraw)
        }
    }, [userDict])

    const dispatch = useDispatch()

    const get_local_state = (mpCompounder: any) => {
        const initial_app_state = mpCompounder.appState;
        const staking_contract_local = initial_app_state["contractState"]["staking_contract"]
        return staking_contract_local
    }

    useEffect(() => {
        const stakingContractLocal = get_local_state(mpCompounder)
        setLocalAppState(stakingContractLocal)
    }, [mpCompounder])

    const claimStake = () => {
        dispatch(claimStakeFunctionStart({mpCompounder}))
    }

    const [modalOpen, setModalOpen] = useState(false)
    const [modalData, setModalData] = useState<null | object>(null)

    useEffect(() => {
        const depositData = {stakingContactLocal, mpCompounder, asset, contractName }
    
        if (modalOpen) {
          setModalData(depositData)
        } else {
          setModalData(null)
        }
    }, [modalOpen, stakingContactLocal, mpCompounder, asset, contractName])

    return ( 
        <div>
            <WithdrawModalComponent mpCompounder={mpCompounder} stakingContactLocal={stakingContactLocal} assetName={asset} contractName={contractName} />
            {modalData !== null ? <DepositModalComponent modalData={modalData} openModal={modalOpen} closeModal={setModalOpen} /> : null}
            <div className="p-3 sm:p-10 mt-10 bg-main-00">
                <div className="sm:flex justify-between" >
                    <div className="sm:w-1/2">
                        <h1 className="text-xl font-semibold leading-8" >Pool In</h1>
                        <p className="text-sm mt-2 text-sides-03">Start earning by depositing more</p>
                    </div>
                    <div className="sm:text-right sm:mt-0 mt-4">
                        <div className="" >
                            <h1 className="text-xl text-white font-semibold" >My Liquidity</h1>
                        </div>
                        <p className="uppercase mt-2 text-white font-semibold" >
                            {backersDeposit ? (backersDeposit).toFixed(2) : 0}<span className="text-main-02 pl-2 text-sm">Zil</span>
                        </p>
                        <div className=" italic bg-main-01  cursor-default font-medium text-white text-sm p-5 mt-4" >
                            <div className="">Deposit</div>
                            <div className="sm:flex grid gap-5 mt-2" >
                                <CustomButton disabled={false} onClick={() => setModalOpen(true)} >via DirectDeposit </CustomButton>
                                <CustomButton disabled={true} >via StakeTransfer</CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            {backersDeposit ?        
                <div className="sm:flex justify-between mt-10" >
                    <div className="sm:w-1/2">
                        <h1 className="text-xl font-semibold leading-8" >Pool Out</h1>
                        <p className="text-sm mt-2 text-sides-03">Withdraw your deposited assets</p>
                    </div>
                    <div className="sm:mt-0 mt-4" >
                        <CustomButton disabled={false} onClick={() => dispatch(withdrawOpen())} >Withdraw</CustomButton>
                    </div>
                </div>
                : null
            }
            </div>
            {
            userDict !== null && userDict.length !== 0 ? 
                <div className="mt-10 bg-main-00">
                    <div className="bg-sides-01 flex rounded-b-md justify-between text-sm px-10 py-10 text-white" >
                        <div>
                            <p>Pending Stake Withdrawals</p>
                            <p className="italic mt-4">You can now withdraw {withdrawAmount} <span className="text-main-02"> Zil</span></p>
                        </div>
                        <div>
                            <CustomButton disabled={withdrawAmount > 0 ? false : true} onClick={() => claimStake()} >Claim Stake</CustomButton>
                        </div>
                    </div>
                    <div className="text-sm py-5 w-full text-main-03">
                        <table className="" >
                            <thead>
                                <tr>
                                    <th>Pending Blocks Till Claim</th>
                                    <th>Progress</th>
                                    <th>Amount in Zil</th>
                                </tr>
                            </thead>
                            <tbody className="text-white">
                                {userDict.map(({request_block_num, blocksPending, progress_per, user_withdraw_amount}: any) =>                                                                  
                                    <tr>
                                        <td>{Math.max(blocksPending,0)}</td>
                                        <td>{Math.min(progress_per,100)}%</td>
                                        <td>{(user_withdraw_amount/1000000000000).toFixed(2)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                : null
            }
            {/*
            {interestInfo ?
                <div className="sm:flex justify-between mt-10" >
                    <div className="sm:w-1/2">
                        <h1 className="text-xl font-semibold leading-8" >Update Prize Pool Contribution</h1>
                        <p className="text-sm mt-2 text-sides-03">Modify prize pool contribution (%) from the rewards.</p>
                    </div>
                    <div className="sm:text-right sm:mt-0 mt-4">
                        <div className="flex items-center" >
                            <h1 className="text-xl text-white font-semibold">Current Contribution (%)</h1>
                        </div>
                        <p className="uppercase mt-2 text-white font-semibold" >
                             {interestInfo ? 100-interestInfo : 0} <span className="text-main-02">%</span>
                        </p>
                        <div className="mt-2" >
                            <CustomButton disabled={false} onClick={() => interestOpen()}>Update Contribution</CustomButton>
                        </div>
                    </div>
                </div>
                : null
            }
            </div>
            
            <div  className="sm:flex items-start justify-between p-3 sm:p-10 mt-10 bg-main-00">
                <div>
                    {claimMaturedStake ? <div>Claim your matured stake</div> : <div className="font-medium" >No stake available to claim</div>}
                    <div className="mt-2">
                        <CustomButton disabled={claimMaturedStake ? false : true} onClick={() => claimStakeFunctionStart({mustpoolContract})}>Claim stake</CustomButton>
                    </div>
                </div>
                <UserLottery mustpoolAutoCompounderAddress={mustpoolContract} userLottery={userLottery} />
            </div>*/}
        </div>
     );
}

const mapStateToProps = (state: never, ownProps: {walletBase16: string}) => ({
    interestInfo: selectFilteredData(ownProps.walletBase16)(state),    
    backersDeposit: selectFilteredBackersDeposit(ownProps.walletBase16)(state),
    userLottery: selectFilteredUserLottery(ownProps.walletBase16)(state),
})
 
export default connect(mapStateToProps)(UserTranaction);
