export const fetchUserDeposit = (intialAppState: any, base16: string) => {

    let backersRealizedDeposit = parseInt(intialAppState["contractState"]["backers_realized_deposit"][base16.toLowerCase()]);

        if (isNaN(backersRealizedDeposit)) {
            backersRealizedDeposit = 0
        }

    const userBufferDeposit = intialAppState["contractState"]["backers_buffered_deposit"][base16.toLowerCase()];

    let usersTotalDeposit: number = 0;
        
    usersTotalDeposit += backersRealizedDeposit;

    for (const reward_cycle in userBufferDeposit) {
        let user_buffer_amount = parseInt(userBufferDeposit[reward_cycle]);
        usersTotalDeposit += user_buffer_amount;
    }

    return usersTotalDeposit/1000000000000
}