import {ActionTypes} from './menu.types';

export interface Action {
  type: ActionTypes.TOGGLE_MENU_OPEN,
}

const INITIAL_STATE = {
  menuOpen: false
};

const menuReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MENU_OPEN:
      return {
        ...state,
        menuOpen: !state.menuOpen
      }
    default:
      return state;
  }
};

export default menuReducer;