import toast from 'react-hot-toast';
import { takeLatest, call, put, all } from 'redux-saga/effects'
import { withdrawFunctionFailure, withdrawFunctionSuccess } from '../transaction.actionDispatcher';
import {transactionTypes} from '../transaction.types';
import { Transaction } from "@zilliqa-js/account";


export interface ResponseGenerator{
    depositTransition: Promise<Transaction>,
    hash: string
}

export function* withdrawFunctionAsync({data}: any) {
    const {mpCompounder, amount, stakingContactLocal} = data
    const zil_amount_in_zil = amount * 1000000000000
    //const contractProxy = zilPay.contracts.at(mustpoolContract);\
    try {
        const withdrawTransaction: ResponseGenerator = yield mpCompounder.PoolOut(zil_amount_in_zil, stakingContactLocal);
        console.log("Tx broadcasted, Waiting for tx finish!");
        if (withdrawTransaction) {
            console.log(`\ntx hash: ${withdrawTransaction.hash}\n`);
            const hash = withdrawTransaction.hash
            yield put(withdrawFunctionSuccess({hash,  mpCompounder}))
            console.log({mpCompounder})
        }
    } catch (error: unknown) {
        console.log({error})
        if (typeof error == "string") { 
          yield put(withdrawFunctionFailure(error))
          toast.error(error)
        }
    }
}

export function* withdrawTransactionStart() {
    yield takeLatest(transactionTypes.START_WITHDRAW_TRANSACTION,
        withdrawFunctionAsync)
}

export function* withdrawTransactionSagas() {
    yield(all([
        call(withdrawTransactionStart),
    ])
    );
};