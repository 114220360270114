export enum transactionTypes {
    START_DEPOSIT_TRANSACTION = "START_DEPOSIT_TRANSACTION",
    DEPOSIT_TRANSACTION_SUCCESS = "DEPOSIT_TRANSACTION_SUCCESS",
    DEPOSIT_TRANSACTION_FAILURE = "DEPOSIT_TRANSACTION_FAILURE",
    START_WITHDRAW_TRANSACTION = "START_WITHDRAW_TRANSACTION",
    WITHDRAW_TRANSACTION_SUCCESS = "WITHDRAW_TRANSACTION_SUCCESS",
    WITHDRAW_TRANSACTION_FAILURE = "WITHDRAW_TRANSACTION_FAILURE",
    START_CHANGE_INTEREST_TRANSACTION = "START_CHANGE_INTEREST_TRANSACTION",
    CHANGE_INTEREST_TRANSACTION_SUCCESS = "CHANGE_INTEREST_TRANSACTION_SUCCESS",
    CHANGE_INTEREST_TRANSACTION_FAILURE = "CHANGE_INTEREST_TRANSACTION_FAILURE",
    START_CLAIM_STAKE_TRANSACTION = "START_CLAIM_STAKE_TRANSACTION",
    CLAIM_STAKE_TRANSACTION_SUCCESS = "CLAIM_STAKE_TRANSACTION_SUCCESS",
    CLAIM_STAKE_TRANSACTION_FAILURE = "CLAIM_STAKE_TRANSACTION_FAILURE",
    START_CLAIM_LOTTERY_TRANSACTION = "START_CLAIM_LOTTERY_TRANSACTION",
    CLAIM_LOTTERY_TRANSACTION_SUCCESS = "CLAIM_LOTTERY_TRANSACTION_SUCCESS",
    CLAIM_LOTTERY_TRANSACTION_FAILURE = "CLAIM_LOTTERY_TRANSACTION_FAILURE"
} 
