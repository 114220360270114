import React from "react"

type props = {
    children: React.ReactChild,
    otherprops?: any,
    disabled: boolean
    onClick?:
        | ((e: any) => void)
        | (() => void);
}

const CustomButton: React.FC<props> = ({children, onClick, disabled, ...otherprops}) => {
    return ( 
        <button onClick={onClick} {...otherprops} disabled={disabled} className={`${disabled ? "bg-gray-700 cursor-not-allowed text-white" : "hover:bg-green-300 bg-neon-cyan text-black"} text-sm py-2 px-4 transform transition-all duration-200 hover:scale-105 font-semibold rounded-md`} >
            {children}
        </button>
     );
}
 
export default CustomButton;