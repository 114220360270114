import { connect, useDispatch, useSelector } from "react-redux";
import { selectFilteredBackersDeposit, selectFilteredData, selectFilteredUserLottery } from "../../../redux/data/data.selectors";
//import Timer from "../../Timer/timer";
import { useEffect, useState } from "react";
import { fetchContractStateStart } from "../../../redux/data/data.actionDispatcher";
import UserTransaction from "./userTransaction.container";
import { WalletButton } from "../../customButton/walletButton";
import { update } from "../../../redux/wallet/wallet.actionDispatcher";
import NetworkBadge from "../../../utils/networkBadge";

type props = {
    contractName: string,
    asset: string,
    prizeAmount: string,
    routeName: string,
    poolIndex: number,
    assetIcon: string,
    baseAssetApy: string,
    blocksToNextDraw: number,
    currentBlock: string,
    currentTxBlockRate: number,
    BNum: string,
    userDict: object[],
    mpCompounder: any,
    liquidity: string
  }

const PoolPage: React.FC<props> = ({
    currentBlock,
    contractName,
    asset,
    BNum,
    userDict,
    baseAssetApy,
    poolIndex,
    assetIcon,
    mpCompounder,
    liquidity
}) => {

    //const secsLeft = Math.ceil(blocksToNextDraw / currentTxBlockRate);

    const [transitionBlock, settransitionBlock] = useState<string | null>(null)
    const [claimMaturedStake, setClaimMaturedStake] = useState<boolean>(false)


    const { addressInfo } = useSelector((state: any) => state.wallet.wallet)

    const walletBase16 = addressInfo.byte20

    useEffect(() => {
        if (walletBase16 && userDict != null) {
          //const transitionBlock: = userDict[walletBase16]
          if (transitionBlock != null) {
            const array = Object.entries(transitionBlock);
            const mapped = array.map((array) => array[0]);
            settransitionBlock(mapped[0]);
          }}
      }, [userDict, walletBase16, transitionBlock]);

      useEffect(() => {
        if(transitionBlock != null) {
            if (parseInt(currentBlock) >= parseInt(BNum) + parseInt(transitionBlock)) {
                setClaimMaturedStake(true)
            } else setClaimMaturedStake(false)
        }
      }, [currentBlock, transitionBlock, BNum])

      const dispatch = useDispatch()
      
      useEffect(() => {
        if (mpCompounder !== undefined) {
            dispatch(fetchContractStateStart(mpCompounder))
        }
     }, [mpCompounder, dispatch])

    return ( 
        <div className="text-white bg-main-01 rounded-xl p-2 sm:p-10" >
            <NetworkBadge />
            <div className="bg-main-00 w-2/3 rounded-xl p-3 sm:p-10 flex gap-10" >
                <div className="flex gap-5 items-center" >
                    <div className="flex" >
                        <div className="bg-sides-02 w-10 relative z-10 h-10 flex items-center justify-center rounded-full">
                        <img className="w-6" src={assetIcon} alt="Assest Icon" />
                        </div>
                        <div className="bg-main-01 relative z-0 -ml-3 w-10 h-10 flex items-center justify-center rounded-full"></div>
                    </div>
                    <div>
                        <div className="rounded-sm bg-sides-03 text-xs w-14 py-0.5 mb-1 flex justify-center items-center"><p>Pool <span className="ml-1">#{poolIndex}</span></p></div>
                        <h1 className="text-2xl mb-2">{contractName}<span className="text-main-02 text-sm ml-2" >{asset}</span></h1>
                    </div>
                </div>
                <div className="md:flex gap-5 items-center text-sm block text-gray-400 font-medium" >
                        {/*
                        <div>
                            <p className="mb-2 text-sides-03">Daily Prize</p>
                            <p className="text-5xl tracking-wide uppercase text-white font-semibold" >${prizeAmount}</p>
                        </div>*/}
                        <div className="" >
                            <p className="mb-2 uppercase text-sides-03">Apr</p>
                            <p className="text-sm uppercase text-white" >{baseAssetApy}</p>
                        </div> 
                        <div className="" >
                            <p className="mb-2 uppercase text-sides-03">Pool Liquidity</p>
                            <p className="text-sm uppercase text-white" >{liquidity}</p>
                        </div> 
                    {/*
                    <div className="md:h-32 w-2/12 h-px bg-sides-02 my-4 md:my-0 md:w-px rounded-md" ></div>
                    <div className="">
                        <Timer secsLeft={secsLeft} />
                    </div>
                    */}
                </div>
            </div>
            {
            mpCompounder !== undefined ? 
            <UserTransaction mpCompounder={mpCompounder} claimMaturedStake={claimMaturedStake} asset={asset} contractName={contractName} /> 
            :
            <div className="rounded-md p-3 sm:p-10 mt-10 bg-main-00">
                <h1 className="mb-4">Connect your wallet to see more details</h1>
                <div className="w-40" >
                    <WalletButton onClick={() => dispatch(update)}></WalletButton>
                </div>
            </div>
            }
        </div>
     );
}

const mapStateToProps = (state: never, ownProps: {walletBase16: string}) => ({
    interestInfo: selectFilteredData(ownProps.walletBase16)(state),    
    backersDeposit: selectFilteredBackersDeposit(ownProps.walletBase16)(state),
    userLottery: selectFilteredUserLottery(ownProps.walletBase16)(state),
})
 
export default connect(mapStateToProps)(PoolPage);