import { all, call } from "@redux-saga/core/effects";
import { withdrawTransactionSagas } from "./transactionSagas/withdraw.transaction.saga";
import { depositTransactionSagas } from "./transactionSagas/deposit.transaction.saga";
import { changeInterestTransactionSagas } from "./transactionSagas/changeinterest.transaction.saga";
import { claimStakeTransactionSagas } from "./transactionSagas/claimStake.transaction.saga";
import { claimLotteryTransactionSagas } from "./transactionSagas/claimLottery.transaction.saga";

export function* transactionSagas() {
    yield all([
        call(withdrawTransactionSagas), 
        call(depositTransactionSagas), 
        call(changeInterestTransactionSagas),
        call(claimStakeTransactionSagas),
        call(claimLotteryTransactionSagas)
    ]);
};