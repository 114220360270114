import { ActionTypes } from "./modalTypes";

export const depositOpen = (depositModal: boolean, depositData: any) => ({
    type: ActionTypes.DEPOSIT_MODAL_OPEN,
    payload: {depositModal, depositData}
})

export const withdrawOpen = () => ({
    type: ActionTypes.WITHDRAW_MODAL_OPEN
})

export const interestOpen = () => ({
    type: ActionTypes.INTEREST_MODAL_OPEN
})

export const setSelectedValue = (data: any) => ({
    type: ActionTypes.SET_SELECTED_VALUE,
    payload: data
})