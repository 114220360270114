import AllPools from './allPools.container'

const YieldContainer: React.FC = () => {

  return (
    <div className="mt-10 mx-auto w-11/12" > 
          <p className="mb-2 font-semibold text-white" >All Pools</p>
            <AllPools />
    </div>

  );
};

export default YieldContainer;
