import { transactionTypes } from './transaction.types';
import { Action } from './transaction.action';

const INITIAL_STATE = {
    transactionData: null
};

const shopReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case transactionTypes.START_DEPOSIT_TRANSACTION:
        return {
        ...state,
    }
    case transactionTypes.DEPOSIT_TRANSACTION_SUCCESS:
      return {
      ...state,
      transactionData: action.payload
    }
    case transactionTypes.DEPOSIT_TRANSACTION_FAILURE:
    return {
    ...state,
    Error: action.payload
    }
    case transactionTypes.START_WITHDRAW_TRANSACTION:
        return {
        ...state,
    }
    case transactionTypes.WITHDRAW_TRANSACTION_SUCCESS:
      return {
      ...state,
      transactionData: action.payload
    }
    case transactionTypes.WITHDRAW_TRANSACTION_FAILURE:
    return {
    ...state,
    Error: action.payload
    }
    case transactionTypes.START_CHANGE_INTEREST_TRANSACTION:
        return {
        ...state,
    }
    case transactionTypes.CHANGE_INTEREST_TRANSACTION_SUCCESS:
      return {
      ...state,
      transactionData: action.payload
    }
    case transactionTypes.CHANGE_INTEREST_TRANSACTION_FAILURE:
    return {
    ...state,
    Error: action.payload
    }
    case transactionTypes.START_CLAIM_STAKE_TRANSACTION:
        return {
        ...state,
    }
    case transactionTypes.CLAIM_STAKE_TRANSACTION_SUCCESS:
      return {
      ...state,
      transactionData: action.payload
    }
    case transactionTypes.CLAIM_STAKE_TRANSACTION_FAILURE:
    return {
    ...state,
    Error: action.payload
    }
    case transactionTypes.START_CLAIM_LOTTERY_TRANSACTION:
        return {
        ...state,
    }
    case transactionTypes.CLAIM_LOTTERY_TRANSACTION_SUCCESS:
      return {
      ...state,
      transactionData: action.payload
    }
    case transactionTypes.CLAIM_LOTTERY_TRANSACTION_FAILURE:
    return {
    ...state,
    Error: action.payload
    }
      default:
        return state
    }
};

export default shopReducer;