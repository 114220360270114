import React, { useEffect } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import Header from "./header/Header";
import { transitionMessageAlert } from "./transactionStatus/transactionStatus";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Toaster } from "react-hot-toast";
import { selectTransactionData } from "../redux/transaction/transaction.selector";
import Sidebar from "./sidebar/sidebar";
import { fetchPoolsStart, fetchMpCompounderStart } from "../redux/data/data.actionDispatcher";
import { selectPoolDataForPreview } from "../redux/data/data.selectors";
import Dropdown from "./dropDown/dropDown"
import { startSagas } from "../redux/root.saga";
import YieldsPage from "../pages/yieldsPage";
import Footer from "./footer";
import Disclaimer from "./resoureces/disclaimer";
import Terms from "./resoureces/terms";
import FAQ from "./resoureces/faq";
import ScrollToTop from "../utils/scrollToTop";
import Banner from "./header/banner";
import { fetchWalletStart } from "../redux/wallet/wallet.actionDispatcher";
import { delay } from "./wallet/getConnectedWallet";
import { RefreshCw } from 'react-feather'

type props = {
  transactionHash?: object,
  poolData: object[],
};

const Landing: React.FC<props> = ({ transactionHash }) => {

  let zilPay = (window as any).zilPay;

  const fetchWallet = async () => {
    if (!zilPay) {
      await delay(1500) // wallet injection may sometimes be slow
      zilPay = (window as any).zilPay;
    }
  }

  useEffect(() => {
    fetchWallet()
  }, [])

  console.log({zilPay})

  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      if (zilPay !== undefined) {
        if (zilPay.wallet.isEnable) {
          dispatch(fetchWalletStart())
        }
      }
    }, 500) 
    startSagas();
  }, [zilPay, dispatch]);

  useEffect(() => {
    const transitionHash = async () => {
      if (transactionHash != null) {
        await transitionMessageAlert({transactionHash});
        //dispatch(fetchMpCompounderStart())
      }
    }

    transitionHash()
  }, [transactionHash, dispatch])

  useEffect(() => {
      dispatch(fetchPoolsStart())
}, [dispatch])

  return (
    <div className="">
        <BrowserRouter>
        <ScrollToTop />
          <Route exact path={'/'} render = {() => { return(<Redirect to={'/yields'} />)}} />
          <Banner />
          <Header />
            <div className="flex px-2 pb-8">
              <Sidebar/>
              <Route path={'/disclaimer'} component={Disclaimer} />
              <Route path={'/terms'} component={Terms} />
              <Route path={'/faq'} component={FAQ} />
              <Route path={'/yields'}>
                <div className="max-w-screen-xl p-2 w-auto md:w-full mx-auto pt-8" >
                  <div className="flex justify-between">
                    <div className="w-6/12"></div>
                    <div className="flex gap-2 w-6/12  pl-6 max-w-md items-center">
                      <div className="hidden sm:block" ><Dropdown /></div>
                      <div onClick={() => dispatch(fetchMpCompounderStart(true))} className=" text-main-03 cursor-pointer"><RefreshCw size={16} /></div>
                    </div>
                  </div>
                  <Route component={YieldsPage} />
                </div>
              </Route>
            </div>
            <div className="px-10 border-t border-sides-02 border-opacity-60 py-10">
                <Footer />
            </div>
            <Toaster
                toastOptions={{
                    success: { duration: 6000 },
                    error: { duration: 8000 },
                    loading: { duration: 130000 },
                    className: "font-medium",
                    style: {
                      border: '5px solid #003b4a',
                      borderRadius: '10px',
                      background: '#003340',
                      color: '#fff',
                    },
                }}
            />
        </BrowserRouter>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  transactionHash: selectTransactionData,
  poolData: selectPoolDataForPreview,
})

export default connect(mapStateToProps)(Landing);