import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import WithSpinner from '../components/with-spinner/withSpinner';
import poolPage from './poolPage';
import { selectIsCompounderFetching } from '../redux/data/data.selectors';

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsCompounderFetching
});

const YieldContainerWrapper: any = compose(
  connect(mapStateToProps),
  WithSpinner
)(poolPage);

export default YieldContainerWrapper;