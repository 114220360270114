import { createStore, applyMiddleware } from "redux";

import logger from "redux-logger";

import rootReducer from "./rootReducer";

import sagaMiddleware from "./root.saga"

const middlewares = [sagaMiddleware, logger];

if (process.env.NODE_ENV === 'production') {
    middlewares.pop()
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

