import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withdrawOpen } from "../../redux/modal/modalActionDispatcher";
import { withdrawFunctionStart } from "../../redux/transaction/transaction.actionDispatcher";
import CustomButton from "../customButton/button";
import CustomInput from "../customInput/customInput";
import Max from "./max";
import Modal from "./Modal";

type props = {
    assetName: string,
    contractName: string,
    mpCompounder: any
    stakingContactLocal: object
}

const WithdrawModal: React.FC<props> = ({mpCompounder, stakingContactLocal, assetName, contractName}) => {

    const dispatch = useDispatch()

    const [amount, setAmount] = useState(10);
    const [userBalance, setUserBalance] = useState(0);
    const [fetchingUserBalance, setFetchingUserBalance] = useState(true);

    const { addressInfo } = useSelector((state: any) => state.wallet.wallet)

    useEffect(() => {
            setFetchingUserBalance(true)
            const initial_app_state = mpCompounder.appState;
            const userBalance = parseInt(initial_app_state["contractState"]["backers_realized_deposit"][addressInfo.byte20.toLowerCase()])/1000000000000
            setUserBalance(isNaN(userBalance) ? 0 : userBalance)
            setFetchingUserBalance(false)
    }, [mpCompounder, addressInfo.byte20])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(withdrawOpen())
        if (amount <= userBalance) {
            dispatch(withdrawFunctionStart({mpCompounder, amount, stakingContactLocal}))
        }
    };

    const updateAmount = (e: any) => {
        let { value } = e.target;
        const deposit_amount = parseFloat(value);
        setAmount(deposit_amount)
    };

    const value = useSelector((state: any) => state.modal.selectedValue)

    useEffect(() => {
        const deposit_amount = userBalance*value/100
        setAmount(deposit_amount)
    }, [value, userBalance])

    const openModal = useSelector((state: RootStateOrAny) => state.modal.withdrawModal)

    return ( 
        <Modal openModal={openModal}>
            <div className="text-white">
                <div>
                    <h1 className="text-lg mb-5 text-center leading-6 font-medium">
                        Withdraw {assetName} from {contractName} via MustPool
                    </h1>
                    <div className="flex mt-2 justify-end">
                        {fetchingUserBalance ? 
                                <div className="lds-ring"><div className="white"></div></div> 
                                :
                                <div className="flex gap-2 items-center">
                                    <p className="text-xs font-medium text-white">Balance {userBalance}<span className="font-semibold text-main-02"> ZIL</span></p>
                                </div>
                        }
                    </div>
                    <form onSubmit={handleSubmit} className="mt-2">
                    <CustomInput
                        className="w-full font-medium focus:bg-primary-01 focus:outline-none transition-colors bg-primary-02 p-2 text-xs rounded-md "
                        type="number"
                        placeholder="10"
                        value={amount}
                        onChange={updateAmount}
                    />
                    <Max />
                    {amount > userBalance ? <p className="text-xs mt-2 font-semibold text-red-400 italic">* Please enter value less than your available Balance</p> : null }
                    </form>
                </div>
                <div className="mt-5 flex justify-start gap-5 ">
                    <button
                        type="button"
                        className="mt-3 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => dispatch(withdrawOpen())}
                    >
                        Cancel
                    </button>
                    <CustomButton
                        disabled={amount <= userBalance ? false : true}
                        onClick={(e) => handleSubmit(e)}
                        >
                        Pool Out
                    </CustomButton>
                </div>
            </div>
        </Modal>
     );
}

 
export default WithdrawModal;