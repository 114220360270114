// https://dev.zilliqa.com/docs/dev/dev-started-env/

// export const MAIN_NET = "https://api.zilliqa.com/";
// export const MAIN_NET_CHAIN_ID = 1; // chainId of the mainnet
// export const MAIN_NET_MSG_VERSION = 1; // current msgVersion

// export const TEST_NET = "https://dev-api.zilliqa.com/";
// export const TEST_NET_CHAIN_ID = 333; // chainId of the developer testnet
// export const TEST_NET_MSG_VERSION = 1; // current msgVersion

import { bytes } from "@zilliqa-js/util";

export const DRAND_URL = "https://api.drand.sh/public/latest";

export enum Network {
  mainnet = "mainnet",
  testnet = "testnet",
}
type Networks = keyof typeof Network;

export const APIS: { [key in Networks]: string } = {
  [Network.mainnet]: "https://api.zilliqa.com",
  [Network.testnet]: "https://dev-api.zilliqa.com",
};

export const WSS: { [key in Networks]: string } = {
  [Network.mainnet]: "wss://api-ws.zilliqa.com",
  [Network.testnet]: "wss://dev-ws.zilliqa.com",
};

// export const CONTRACTS: { [key in Networks]: string } = {
//   [Network.mainnet]: "zil1hgg7k77vpgpwj3av7q7vv5dl4uvunmqqjzpv2w",
//   [Network.testnet]: "zil1rf3dm8yykryffr94rlrxfws58earfxzu5lw792",
// };

export enum ILOState {
  Uninitialized = "Uninitialized",
  Pending = "Pending",
  Active = "Active",
  Failed = "Failed",
  Completed = "Completed",
}

export const CHAIN_VERSIONS: { [key in Networks]: number } = {
  [Network.mainnet]: bytes.pack(1, 1),
  [Network.testnet]: bytes.pack(333, 1),
};

export const ZIL_HASH = "0x0000000000000000000000000000000000000000";

export const ZERO = 0;