import { combineReducers } from 'redux'
import dataReducer from './data/data.reducer'
import menuReducer from "./menu/menu.reducer"
import modalReducer from "./modal/modalReducer"
import walletReducer from "./wallet/wallet.reducer"
import transactionReducer from "./transaction/transaction.reducer"


const rootReducer = combineReducers({
    data:   dataReducer,
    menu: menuReducer,
    modal: modalReducer,
    wallet: walletReducer,
    transaction: transactionReducer,
})

export default rootReducer