import toast from 'react-hot-toast';
import { takeLatest, call, put, all } from 'redux-saga/effects'
import { claimLotteryFunctionFailure } from '../transaction.actionDispatcher';
import {transactionTypes} from '../transaction.types';
import { Transaction } from "@zilliqa-js/account";

//const VERSION = CHAIN_VERSIONS.TestNet;
export interface ResponseGenerator{
    depositTransition: Promise<Transaction>,
    ID: string
}

export function* claimLotteryFunctionAsync({data}: any) {
    //const {mustpoolAutoCompounderAddress} = data

    try {
        //const claimLotteryTransition: ResponseGenerator = yield PAYOUT_PRIZE_DRAW_WINNER(zilPay, mustpoolAutoCompounderAddress, txGasPriceInLi, txGasLimit, VERSION)
        //console.log({claimLotteryTransition})
        //yield put(claimLotteryFunctionSuccess(claimLotteryTransition.ID))    
    } catch (error: unknown) {
        console.log({error})
        if (typeof error == "string") { 
          yield put(claimLotteryFunctionFailure(error))
          toast.error(error)
        }
    }
}

export function* claimLotteryFunctionStart() {
    yield takeLatest(transactionTypes.START_CLAIM_LOTTERY_TRANSACTION,
        claimLotteryFunctionAsync)
}

export function* claimLotteryTransactionSagas() {
    yield(all([
        call(claimLotteryFunctionStart),
    ])
    );
};