import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleMenuOpen } from "../../redux/menu/menu.actions";

/*
Sidebar Element
After getting data from the map object in sidebarComponent. This returns individual sidebar elements.
*/

type PathParamsType = {
  param: string,
}

type PropsType = RouteComponentProps<PathParamsType> & {
  history: [],
  svg: ReactElement,
  route: string,
  color: string,
  text: string
  menuOpen?: any
}

const SidebarElements:React.FC<PropsType> = ({text, svg, color, route, menuOpen}) => {

  const [isHover, setIsHover] = useState(false)
  const dispatch = useDispatch()

  console.log({menuOpen})

  const menuClose = () => {
    if (menuOpen) {
      dispatch(toggleMenuOpen())
    }
  }
  
  return (
      <Link
        to={route}        
        className=" font-medium cursor-pointer rounded-sm flex py-2 justify-start items-center"
        onClick={menuClose}
      >
        <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className={`py-3 text-lg text-sides-03 hover:text-white px-6 mx-5 rounded-md hover:border-main-02 border-transparent border-2 w-full flex items-center gap-3 transition-colors`}>
          <div className={`${isHover ? color : "text-sides-03"} transition-colors`} >{svg}</div>
          <p className=" w-full font-semibold">{text}</p>
        </div>
      </Link>
  );
};

export default withRouter(SidebarElements);
