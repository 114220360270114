import React, { ReactElement } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import YieldPool from "../YieldPoolNoLottery";

const YieldContainer: React.FC = () => {

  const poolData = useSelector((state: RootStateOrAny) => Object.keys(state.data.pool).map(key => state.data.pool[key]))
  let mpCompounder = useSelector((state: RootStateOrAny) => state.data.compounder)
  if (mpCompounder === undefined) {
    mpCompounder = []
  }
  const currentTxBlockRate = useSelector((state: RootStateOrAny) => state.data.blockRate)

  return (
      <div className="flex flex-wrap gap-10">
          {poolData.map(({id, ...otherprops}): ReactElement => (
                  <YieldPool
                    mpCompounder={mpCompounder[id]}
                    currentTxBlockRate={currentTxBlockRate}
                    {...otherprops}
                    key={id}
                  />
                ))
            }
      </div>

  );
};

export default YieldContainer;
