import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import WithSpinner from '../../with-spinner/withSpinner';
import { selectIsFetchingPools } from '../../../redux/data/data.selectors';
import poolPageComponent from './poolPage.component';

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingPools
});

const YieldContainerWrapper: any = compose(
  connect(mapStateToProps),
  WithSpinner
)(poolPageComponent);

export default YieldContainerWrapper;