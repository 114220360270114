import { EventChannel, eventChannel } from 'redux-saga';
import { call, put, all, take, select, cancelled, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { ConnectedWallet } from '../../components/wallet/connectedWallet';
import { getConnectedZilPay } from '../../components/wallet/getConnectedWallet';
import { connectWalletZilPay } from '../../components/wallet/wallet';
import { MP_AutoCompounder } from '../../sdk/autocompounder';
import { fetchMpCompounderStart, fetchMpCompounderSuccess, fetchMyPoolsStart, fetchMyPoolsSuccess, fetchPoolsStart } from '../data/data.actionDispatcher';
import { update } from './wallet.actionDispatcher';
import { getData } from '../selector';
import { getWallet } from '../selector';
import { fetchMyPools } from '../data/data.saga';
import { WalletActionTypes } from './wallet.types';
import { ActionTypes } from '../data/data.types';

  const zilPayObserver = (zilPay: any) => {
    return eventChannel<ConnectedWallet>(emitter => {
      const accountObserver = zilPay.wallet.observableAccount();
      const networkObserver = zilPay.wallet.observableNetwork();
  
      accountObserver.subscribe(async (account: any) => {
        console.log(`Zilpay account changed to: ${account.bech32}`)
        const walletResult = await connectWalletZilPay(zilPay);
        if (walletResult?.wallet) {
          emitter(walletResult.wallet)
        }
      });
  
      networkObserver.subscribe(async (net: string) => {
        console.log(`Zilpay network changed to: ${net}`)
        const walletResult = await connectWalletZilPay(zilPay);
        if (walletResult?.wallet) {
          emitter(walletResult.wallet)
        }
      });
  
      console.log('registered zilpay observer')
  
      return () => {
        console.log('deregistered zilpay observer')
      }
    })
  }

  function* watchZilPay() {
    let chan
    while (true) {
      try {
        if (true) {
          console.log('starting to watch zilpay')
          const zilPay = (yield call(getConnectedZilPay)) as unknown as any;
          chan = (yield call(zilPayObserver, zilPay)) as EventChannel<ConnectedWallet>;
          break
        }
      } catch (e) {
        console.warn('Watch Zilpay failed, will automatically retry on reconnect. Error:')
        console.warn(e)
      }
    }
    try {
      while (true) {
        const newWallet = (yield take(chan)) as ConnectedWallet
        const { wallet: oldWallet } = yield select(getWallet)
        //if (oldWallet?.type !== WalletConnectType.ZilPay) continue
        if (newWallet.addressInfo.bech32 === oldWallet?.addressInfo.bech32 &&
          newWallet.network === oldWallet.network) continue
          yield all([
              put(update({wallet: newWallet})),
              put(fetchPoolsStart()),
              call(fetchMpCompounder)
          ])
        //yield put(actions.Blockchain.initialize({ wallet: newWallet, network: newWallet.network }))
      }
    } finally {
      if (cancelled()) {
        chan.close()
      }
    }
  }

  //function to fetch and return mpCompunder Dictionary

  function* fetchMpCompounderAsync() {
    const { pool } = yield select(getData)
    const poolData = Object.keys(pool).map(key => pool[key])
    const { wallet } = yield select(getWallet)
    const provider = wallet.provider
    const network = wallet.network
    yield put(fetchMyPoolsSuccess([]))
    yield put(fetchMyPoolsStart())
    try {
      const mpCompounder = async(poolData: any[]) => {
        let mpCompounderDict: any = [];
        let i = 0;
        while (i < poolData.length) {
            const mpCompounder = await getMpCompounder(poolData[i]);
            if (mpCompounder !== undefined) {
                mpCompounderDict.push(mpCompounder);
            }
            i++;
        }
          return await mpCompounderDict.reduce((accumulator: any, mpCompounderId: any) => {
              accumulator[mpCompounderId.objId.toLowerCase()] = mpCompounderId;
              return accumulator;
          }, {});
        }

      const getMpCompounder = async (poolData: any) => {
        console.log("Contract", poolData.mustpoolContract)
          const compounder = new MP_AutoCompounder(network, String(poolData.mustpoolContract), String(poolData.id), Object(provider),)
          await compounder.initialize()
          return await compounder
      }

      return (yield mpCompounder(poolData)) as object
    } catch {

    }
  }

// function to fetch mpcompounder initially. making two function to refetch and refetch stops re-rendering need to work

export function* fetchMpCompounder() {
  console.log("SDk initialize")
  const { pool } = yield select(getData)
  const { wallet } = yield select(getWallet)
    if (pool.length !== 0 && wallet !== null) {
      yield put(fetchMpCompounderStart(true))
      try {
        const mpCompounder = (yield fetchMpCompounderAsync()) as object   
        yield all([
          put(fetchMpCompounderSuccess(mpCompounder)),
          call(fetchMyPools)
      ])
      } catch (err) {
            console.log(err)
      }
  }
}

// function to refetch mpcompounder

function* RefetchMpCompounder() {
  const { compounder } = yield select(getData)
  if (compounder !== undefined) {
    if (compounder.length !== 0) {
      try {
        const mpCompounder = (yield fetchMpCompounderAsync()) as object
        yield all([
          put(fetchMpCompounderSuccess(mpCompounder)),
          call(fetchMyPools)
        ])
      } catch {

      }
      }
    }
  }

function* walletSaga() {
    yield fork(watchZilPay)
};

export function* StartWalletSagas() {
  yield takeEvery(WalletActionTypes.FETCH_WALLET_START,
    walletSaga)
}

export function* RestartMpCompounder() {
    yield takeLatest(ActionTypes.FETCH_MP_COMPOUNDER_START,
      RefetchMpCompounder)
}