export default function Banner() {
  return (
    <div
      style={{ boxShadow: "1px -1px 15px 6px rgba(1, 249, 198, 8%)" }}
      className="relative bg-transparent "
    >
      <div className="max-w-7xl mx-auto text-center py-3 px-3 sm:px-6 lg:px-8 text-sm font-medium text-white">
        <div className="flex items-center gap-2 justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-red-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <p>
            Note: In beta. Click
            <a
              href="https://docs.google.com/spreadsheets/d/1PwdKerG5uMEVWEeP4BagaG1NyUOk0O6s2qGgi_elJzQ"
              target={"_blank"}
              rel="noreferrer"
              className="text-main-03 font-medium"
            >
              {" "}
              here{" "}
            </a>
            to check if your address is allowed. Register your interest
            <a
              href="https://forms.gle/7wGbZuuxC21HvcbZ7"
              target={"_blank"}
              rel="noreferrer"
              className="text-main-03 font-medium"
            >
              {" "}
              here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
