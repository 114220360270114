import toast from 'react-hot-toast';
import { takeLatest, call, put, all } from 'redux-saga/effects'
import { changeInterestFunctionFailure } from '../transaction.actionDispatcher';
import {transactionTypes} from '../transaction.types';
import { Transaction } from "@zilliqa-js/account";

export interface ResponseGenerator{
    depositTransition: Promise<Transaction>,
    ID: string
}

export function* changeInterestFunctionAsync({data}: any) {
    //const {mustpoolAutoCompounderAddress, restake_percent} = data
    try {
        //const changeInterestTransition: ResponseGenerator = yield UpdateRestakeAndPrizePoolContributions(zilPay, mustpoolAutoCompounderAddress)
        //yield put(changeInterestFunctionSuccess(changeInterestTransition.ID))    
    } catch (error: unknown) {
        console.log({error})
        if (typeof error == "string") { 
          yield put(changeInterestFunctionFailure(error))
          toast.error(error)
        }
    }
}

export function* changeInterestFunctionStart() {
    yield takeLatest(transactionTypes.START_CHANGE_INTEREST_TRANSACTION,
        changeInterestFunctionAsync)
}

export function* changeInterestTransactionSagas() {
    yield(all([
        call(changeInterestFunctionStart),
    ])
    );
};