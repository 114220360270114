const messageCodes = {
    "2": { 
        alert: "Confirmed", 
        name: "Confirmed", 
        type: "success" 
    },
    "3": {
        alert: "Rejected",
        name: "Rejected",
        type: "error",
    },
};

const decodeMessage = (code) => {
    return messageCodes[code];
};

export const decodeZilPayError = (error) => {
    console.log(error);
    switch (error) {
        case "Insufficient funds in source account!":
            return "Insufficient funds for transaction";
        case "User rejected":
            return "Transaction rejected from ZilPay";
        default:
            return "ZilPay Error";
    }
};

export default decodeMessage;