import { Action } from './data.action';
import { ActionTypes } from './data.types';

const INITIAL_STATE = {
  pool: [],
  myPool: null,
  currentBlock: null,
  currentTxBlockRate: null,
  bnum: null,
  userDict: null,
  interestData: null,
  backersDeposit: null,
  userLottery: null,
  isPoolsFetching: false,
  isUserDataFetching: false,
  isBlockRateFetching: false,
  isMyPoolsFetching: false,
  isCompounderFetching: true,
  compounder: []
};

const dataReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ActionTypes.FETCH_POOLS_START:
      return {
        ...state,
        isPoolsFetching: true
      };

    case ActionTypes.FETCH_POOLS_SUCCESS: 
      return {
        ...state,
        isPoolsFetching: false,
        pool: action.payload
      };

    case ActionTypes.FETCH_MYPOOLS_START:
    return {
      ...state,
      isMyPoolsFetching: true
    };

    case ActionTypes.FETCH_MYPOOLS_SUCCESS: 
    return {
      ...state,
      isMyPoolsFetching: false,
      myPool: action.payload
    };

    case ActionTypes.FETCH_BLOCK_RATE_SUCCESS: 
      return {
        ...state,
        currentTxBlockRate: action.payload,
    };

    case ActionTypes.FETCH_CURRENT_BLOCK_SUCCESS: 
      return {
        ...state,
        currentBlock: action.payload,
    };
      
    case ActionTypes.FETCH_CONTRACT_STATE_START:
      return {
        ...state,
        isUserDataFetching: true
    };

    case ActionTypes.FETCH_B_NUM_SUCCESS: 
      return {
        ...state,
        bnum: action.payload,
        isUserDataFetching: false
    };

    case ActionTypes.FETCH_USER_WITHDRAW_DICT_SUCCESS: 
      return {
        ...state,
        userDict: action.payload,
        isUserDataFetching: false
    };

    case ActionTypes.FETCH_INTEREST_DATA_SUCCESS: 
      return {
        ...state,
        interestData: action.payload,
        isUserDataFetching: false
    };

    case ActionTypes.FETCH_BACKERS_DEPOSIT_SUCCESS: 
      return {
        ...state,
        backersDeposit: action.payload,
        isUserDataFetching: false
    };

    case ActionTypes.FETCH_USER_LOTTERY_SUCCESS: 
      return {
        ...state,
        userLottery: action.payload,
        isUserDataFetching: false
    };

    case ActionTypes.FETCH_MP_COMPOUNDER_START: 
      return {
        ...state,
        isCompounderFetching: true
      }

    case ActionTypes.FETCH_MP_COMPOUNDER_SUCCESS: 
      return {
        ...state,
        compounder: action.payload,
        isCompounderFetching: false
    };

      default:
        return state
    }
};

export default dataReducer;