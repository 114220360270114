import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import "./hamburger.css";
import { createStructuredSelector } from "reselect";
import { selectMenuOpen } from "../../redux/menu/menu.selector";
import { toggleMenuOpen } from "../../redux/menu/menu.actions";
import { fetchWalletStart } from "../../redux/wallet/wallet.actionDispatcher";
import { connect, RootStateOrAny, useDispatch, useSelector } from "react-redux";
import CustomButton from "../customButton/button";
import MyDropdown from "./walletPopover";
//import { Toggle } from "./toggle"

type props = {
  menuOpen: boolean;
};

const Header: React.FC<props> = ({ menuOpen }) => {
  const wallet = useSelector((state: RootStateOrAny) => state.wallet.wallet);

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="py-2 transition-all bg-main-00 w-full px-2 md:px-14">
      <div className="flex justify-between items-center py-2">
        <div className="flex gap-10 items-center w-3/5">
          <button
            onClick={() => dispatch(toggleMenuOpen())}
            className={`hamburger inline-block lg:hidden hamburger--squeeze ${
              menuOpen ? "is-active " : ""
            }`}
            type="button"
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <div className="flex justify-between items-center md:w-1/2">
            <div
              onClick={() => history.push("/yields")}
              className="text-blue-500 font-semibold w-28 cursor-pointer"
            >
              <img src="/assests/logo.webp" alt="logo" />
            </div>
          </div>
        </div>
        <div className="flex md:gap-7 gap-2 justify-center items-center w-2/5">
          <div className="sm:block hidden">
            {
              //<Toggle />
            }
          </div>
          <div>
            {wallet ? (
              <MyDropdown />
            ) : (
              <CustomButton
                disabled={false}
                onClick={() => dispatch(fetchWalletStart())}
              >
                Connect ZilPay
              </CustomButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  menuOpen: selectMenuOpen,
});

export default withRouter(connect(mapStateToProps)(Header));
