import Landing from "./components/Landing";

const App = () => {

  return (
    <div style={{backgroundImage: "linear-gradient(to top, #0b161e, #0c1820, #0d1921, #0e1a23, #0e1c25)"}} className="app">
      <Landing />
    </div>
  );
}

export default App;
