import { WalletState } from './wallet.action';
import { WalletActionTypes } from './wallet.types';

const INITIAL_STATE: WalletState = {
  wallet: null,
};

export const LocalStorageKeys = {
  ZilPayConnected: "mustpool:zilpay-connected",
};

const walletReducer = (state: WalletState = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case WalletActionTypes.FETCH_WALLET_START: {
      return {
        ...state
      }
    }
    case WalletActionTypes.WALLET_UPDATE: {
      const { payload } = action;
      return {
         ...state, 
         ...payload
      };
    }
      default:
        return state
    }
};

export default walletReducer;